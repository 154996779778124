import React from 'react'
import { OurPartnerImg } from '../../hardcoded/OurPartnersImg'
import '../../styles/index.css'

const OurPartner = () => {
  return (
    <>
      <div className="mx-auto text-center font-body mt-16">
        <p className="font-bold text-[41px] mb-5"> Partner Kami</p>
        <hr className="border-black border-[2px] w-[50px] rounded mb-10 mx-auto"></hr>
        <div className="flex justify-center">
          <div className="grid md:grid-cols-4 grid-cols-2">
            {OurPartnerImg.map((image) => {
              return (
                <div className="mx-16 my-3">
                  {/* <img className="w-40 rounded-lg" src={image}></img> */}
                  <picture>
                    <source type='image/webp' srcSet={image.imageWebp} className="w-40 rounded-lg"/>
                    <source type='image/jpeg' srcSet={image.imageJpg} className="w-40 rounded-lg"/>
                    <img src={image.imageJpg} className="w-40 rounded-lg" alt=''/>
                  </picture>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex sm:flex-row flex-col justify-center my-10">
          <div className="flex flex-col items-center mx-5">
            <p className="font-medium text-[18px]"> Berizin dan diawasi oleh</p>
            <img
              className="mt-5 w-[200px]"
              src={require("../../images/Otoritas Jasa Keuangan.png")}
            ></img>
          </div>
          <div className="flex flex-col items-center mx-5">
            <p className="font-medium text-[18px]"> Anggota terdaftar dari</p>
            <img
              className="mt-5 w-[200px]"
              src={require("../../images/Asset Icons Website IKI Modal/Group 600.png")}
            ></img>
          </div>
          <div className="flex flex-col justify-center">
            <p className="font-medium text-[18px] text-center">Certified by</p>
            <img
              className="mt-5 w-[100px]"
              src={require("../../images/Asset Icons Website IKI Modal/pngegg-2.png")}
            ></img>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurPartner