// export const OurPartnerImg = [
//   require("../images/Partner Kami/WhatsApp Image 2022-05-23 at 4.26.56 PM.jpeg"),
//   require("../images/Partner Kami/WhatsApp Image 2022-05-23 at 4.26.56 PM (1).jpeg"),
//   require("../images/Partner Kami/WhatsApp Image 2022-05-23 at 4.26.56 PM (2).jpeg"),
//   require("../images/Partner Kami/WhatsApp Image 2022-05-23 at 4.26.56 PM (3).jpeg"),
//   require("../images/Partner Kami/WhatsApp Image 2022-05-23 at 4.26.56 PM (4).jpeg"),
//   require("../images/Partner Kami/WhatsApp Image 2022-05-23 at 4.26.56 PM (5).jpeg"),
//   require("../images/Partner Kami/WhatsApp Image 2022-05-23 at 4.26.56 PM (6).jpeg"),
//   require("../images/Partner Kami/WhatsApp Image 2022-05-23 at 4.26.56 PM (7).jpeg"),
//   require("../images/Partner Kami/WhatsApp Image 2022-05-23 at 4.26.56 PM (8).jpeg"),
//   require("../images/Partner Kami/WhatsApp Image 2022-05-23 at 4.26.56 PM (9).jpeg"),
//   require("../images/Partner Kami/WhatsApp Image 2022-05-23 at 4.26.56 PM (10).jpeg"),
//   require("../images/Partner Kami/WhatsApp Image 2022-05-23 at 4.26.56 PM (11).jpeg"),
//   require("../images/Partner Kami/WhatsApp Image 2022-05-23 at 4.26.56 PM (12).jpeg"),
//   require("../images/Partner Kami/WhatsApp Image 2022-05-23 at 4.26.56 PM (13).jpeg"),
//   require("../images/Partner Kami/WhatsApp Image 2022-05-23 at 4.26.56 PM (14).jpeg"),
//   require("../images/Partner Kami/WhatsApp Image 2022-05-23 at 4.26.56 PM (15).jpeg"),
// ];

export const OurPartnerImg = [
  {
    imageJpg: require("../images/JPG/Partner Kami/anabatictechnologies.jpeg"),
    imageWebp: require("../images/Webp/Partner Kami/anabatictechnologies.webp"),
  },
  {
    imageJpg: require("../images/JPG/Partner Kami/bluepowertech.jpeg"),
    imageWebp: require("../images/Webp/Partner Kami/bluepowertech.webp"),
  },
  {
    imageJpg: require("../images/JPG/Partner Kami/bankbri.jpeg"),
    imageWebp: require("../images/Webp/Partner Kami/bankbri.webp"),
  },
  {
    imageJpg: require("../images/JPG/Partner Kami/centraldatatech.jpeg"),
    imageWebp: require("../images/Webp/Partner Kami/centraldatatech.webp"),
  },
  {
    imageJpg: require("../images/JPG/Partner Kami/ctigroup.jpeg"),
    imageWebp: require("../images/Webp/Partner Kami/ctigroup.webp"),
  },
  {
    imageJpg: require("../images/JPG/Partner Kami/defenxor.jpeg"),
    imageWebp: require("../images/Webp/Partner Kami/defenxor.webp"),
  },
  {
    imageJpg: require("../images/JPG/Partner Kami/emporiadigital.jpeg"),
    imageWebp: require("../images/Webp/Partner Kami/emporiadigital.webp"),
  },
  {
    imageJpg: require("../images/JPG/Partner Kami/equineglobal.jpeg"),
    imageWebp: require("../images/Webp/Partner Kami/equineglobal.webp"),
  },
  {
    imageJpg: require("../images/JPG/Partner Kami/inovasiinformatikaindonesia.jpeg"),
    imageWebp: require("../images/Webp/Partner Kami/inovasiinformatikaindonesia.webp"),
  },
  {
    imageJpg: require("../images/JPG/Partner Kami/jedisolutions.jpeg"),
    imageWebp: require("../images/Webp/Partner Kami/jedisolutions.webp"),
  },
  {
    imageJpg: require("../images/JPG/Partner Kami/jtrustbank.jpeg"),
    imageWebp: require("../images/Webp/Partner Kami/jtrustbank.webp"),
  },
  {
    imageJpg: require("../images/JPG/Partner Kami/kreditplus.jpeg"),
    imageWebp: require("../images/Webp/Partner Kami/kreditplus.webp"),
  },
  {
    imageJpg: require("../images/JPG/Partner Kami/magna.jpeg"),
    imageWebp: require("../images/Webp/Partner Kami/magna.webp"),
  },
  {
    imageJpg: require("../images/JPG/Partner Kami/megabuanateknologi.jpeg"),
    imageWebp: require("../images/Webp/Partner Kami/megabuanateknologi.webp"),
  },
  {
    imageJpg: require("../images/JPG/Partner Kami/vitrus.jpeg"),
    imageWebp: require("../images/Webp/Partner Kami/vitrus.webp"),
  },
  {
    imageJpg: require("../images/JPG/Partner Kami/xdcindonesia.jpeg"),
    imageWebp: require("../images/Webp/Partner Kami/xdcindonesia.webp"),
  },
];