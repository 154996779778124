import React from 'react'

const ProductPinjamanDesc = ({ data }) => {
  return (
    <div className='mx-auto w-[90%] flex flex-col items-center my-[10rem] font-body'>
      <p className="font-bold text-[41px] text-center">
        {data.title}
      </p>
      <hr className="border-black border-[2px] w-[50px] rounded my-5 text-center"></hr>
      <div className='p-5 flex justify-between md:flex-row flex-col'>
        {/* <img className="xl:w-[17rem] md:w-[14rem] w-[10rem] h-auto md:mr-5 mx-auto" src={data.image} alt="Invoice Financing" /> */}
        <picture>
          <source type='image/webp' srcSet={data.imageWebp} className='xl:w-[17rem] md:w-[14rem] w-[10rem] h-auto md:mr-5 mx-auto'></source>
          <source type='image/png' srcSet={data.imagePng} className='xl:w-[17rem] md:w-[14rem] w-[10rem] h-auto md:mr-5 mx-auto'></source>
        <img className="xl:w-[17rem] md:w-[14rem] w-[10rem] h-auto md:mr-5 mx-auto" src={data.imagePng}/>
        </picture>
        <div className="md:w-[30rem] md:mt-0 mt-3 font-extralight text-lg">
          <p>{data.paragraphone}</p> <br />
          <p>{data.paragraphtwo}</p>
        </div>
      </div>
    </div>
  )
}

export default ProductPinjamanDesc