import React from 'react'
import Subheader from './homepage/Subheader';
import ImageSlider from './homepage/ImageSlider';
import Simulasi from './homepage/Simulasi';
import Portofolio from './homepage/Portofolio';
import PortofolioSubContent from './homepage/PortofolioSubContent';
import SubPilihanPinjaman from './homepage/SubPilihanPinjaman';
import OurPartner from './homepage/OurPartner';

import { useEffect, useState } from "react";
import { tkbData, pinjamanMarketplace } from "../services/data";


const HomePage = () => {
  const [data, setData] = useState([]);
  const [pinjaman, setPinjaman] = useState([]);

  useEffect(() => {
    apiTkb();
    apiPinjamanMarketplace();
  }, []);
  const apiTkb = async () => {
    const tes = await tkbData();
    setData(tes);
  };

  const apiPinjamanMarketplace = async () => {
    const data = await pinjamanMarketplace();
    setPinjaman(data);
  }

  return (
    <div className="max-w-[100%] overflow-x-hidden">
      <ImageSlider/>
      <Subheader/>
      {/* <Simulasi/> */}
      <Portofolio data={data}/>
      <PortofolioSubContent data={data}/>
      <div className="relative">
        <img src={require("../images/Asset Icons Website IKI Modal/Vector 1.png")} alt="" className=" overflow-x-hidden -z-30 absolute bottom-[10rem] -right-[45rem]" />
        <img src={require("../images/Asset Icons Website IKI Modal/Dots.png")} alt="dots" className="absolute -z-30 bottom-[10rem] -left-[1rem] w-[4.5rem]" />
      </div>
      <OurPartner/>
    </div>
  )
}

export default HomePage