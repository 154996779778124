
const SyaratPengajuan = ({ data }) => {
  const syaratPengajuan = data.syaratPengajuan;
  console.log(syaratPengajuan)
  syaratPengajuan.map((syarat, i) => {
    console.log(syarat)
  })

  const SyaratComponent = ({ syarat, index }) => {
    return(
      <div className={`flex ${(index !== 0) ? "mt-5" : ""}`}>
        <img src={require("../images/Asset Icons Website IKI Modal/checked.png" )} className="w-[3rem] h-auto" alt='Checked'></img>
        <h4 className="ml-10 md:text-xl">{syarat}</h4>
      </div>
    )
  }
  
  return (
    <div className="py-20 items-center bg-[#FFE9E9] font-body">
      <p className="font-bold text-[36px] text-center">Syarat Pengajuan</p>
      <hr className="border-black border-[2px] w-[50px] rounded my-5 text-center mx-auto" />
      <div className="flex justify-evenly md:flex-row flex-col-reverse w-[70%] mx-auto">
        <div className="flex flex-col justify-center">
          {syaratPengajuan.map((syarat, i) => {
            return (<SyaratComponent syarat={syarat} index={i} />)
          })}
        </div>
        <img src={require("../images/Asset Icons Website IKI Modal/syaratPengajuan.png" )} className="md:w-[17rem] w-[10rem] md:h-auto" alt='Syarat Pengajuan'></img>
      </div>
    </div>
  )
}

export default SyaratPengajuan