
import { useState } from "react";
import {kebijakanPerlindungan} from "../../hardcoded/FaqData"
import AccordionItems2 from "../AccordionItems2";


const SectionKebijakan = () => {

    const [open, setOpen] = useState(false);

    
    const toggle = (index) => {
        if(open === index) {
            return setOpen(null)
        }
        setOpen(index)
    }

    
  return (
    <>
      {kebijakanPerlindungan.map((item, i) => {
        return (
          <div
            className="container mx-auto flex flex-col items-center my-20 font-body"
            key={i}
          >
            <h4 className="font-bold text-[41px]">{item.section}</h4>
            <hr className="border-black border-[2px] w-[50px] rounded my-5"></hr>

            <div className="grid grid-cols-1 px-5">
              {item.group.map((group, g) => {
                return (
                  <>
                    <div key={g} className="mb-10 mt-10">
                      <div className="text-[28px] font-semibold text-gray-600">{group.headerTitle} </div>
                    </div>

                    {group.content.map((data, index) => {
                      return (
                        <AccordionItems2
                          key={index}
                          open={index === open}
                          title={data.title}
                          subtitle={data.subtitle}
                          imageUrl={data.imageUrl}
                          imageUrl2={data.imageUrl2}
                          desc={data.desc}
                          toggle={() => toggle(index)}
                        />
                      );
                    })}
                  </>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default SectionKebijakan