
import {mekanismeLayanan} from "../../hardcoded/FaqData"

const SectionLayanan = () => {

   
  return (
    <>

      {mekanismeLayanan.map((item, i) => {
        return (
          <div
            className="container mx-auto flex flex-col items-center my-20 font-body"
            key={i}
          >
            <h4 className="font-bold text-[41px]">{item.section}</h4>
            <hr className="border-black border-[2px] w-[50px] rounded my-5"></hr>

            <div className="grid grid-cols-1 px-5">
              {item.content.map((data, index) => {
                return (
                  <>
                    <div key={index} className="mt-5">
                      <div className="text-[22px] font-semibold text-gray-600">{data.title} 
                        <hr className="border border-gray-600"></hr>
                      </div>

                      <div className="mt-5">
                        <div className="bg-white pb-[20px] text-gray-600" dangerouslySetInnerHTML={{__html:data["desc"]}}></div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default SectionLayanan