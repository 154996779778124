import SectionKebijakan from "../component/faq-component/SectionKebijakan";
import SectionLayanan from "../component/faq-component/SectionLayanan";
import SectionPendana from "../component/faq-component/SectionPendana";
import SectionPeminjam from "../component/faq-component/SectionPeminjam";
import SectionPanduanTtd from "../component/faq-component/SectionPanduanTtd";
import InformasiUmum from "../component/faq-section/InformasiUmum";
import Borrower from "../component/faq-section/Borrower";
import Lender from "../component/faq-section/Lender";
import VirtualAccount from "../component/faq-section/VirtualAccount";


const LogalFaq = () => {

 

  return (
    <>
      <InformasiUmum />
      <Borrower />
      <Lender />
      <VirtualAccount/>
      <SectionKebijakan />
      <SectionPendana />
      <SectionPeminjam />
      <SectionPanduanTtd />
      <SectionLayanan />
    </>
  );
}

export default LogalFaq