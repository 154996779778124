import React from 'react'
import Card from '../../component/Card'
import CardMultiColumn from '../../component/CardMultiColumn';


const Portofolio = (data) => {
    // console.log(data);
  return (
    <>
      <div className="container mx-auto flex flex-col items-center my-[4rem]">
        <p className="font-bold text-[41px] text-center font-body">
          Portofolio IKI Modal
        </p>
        <hr className="border-black border-[2px] w-[50px] rounded my-5 text-center"></hr>
        
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 text-[18px]">
          {/* <Card text="TKB90" api={data?.data?.data?.tkb} /> */}
          <Card
            text="Total Pengajuan Pinjaman (Sejak Berdiri)"
            api={data?.data?.data?.pengajuan_sejak_berdiri}
          />
          <Card
            text={`Total Pengajuan Pinjaman (${new Date().getFullYear()})`}
            api={data?.data?.data?.pengajuan_tahun_berjalan}
          />
          <Card
            text="Nilai Pinjaman Outstanding"
            api={data?.data?.data?.outstanding}
          />

        </div>

        {/* <div className="grid lg:grid-cols-2 sm:grid-cols-1 grid-cols-1 text-[18px]">
          <div>
            <div className='flex flex-col'>
              <div className="w-[30rem] rounded overflow-hidden shadow-md shadow-gray-00 text-center mx-11 my-11 font-portofolio p-7">
                <CardMultiColumn 
                  text="Jumlah Borrower Terdaftar (Sejak Berdiri)"
                  api={data?.data?.data?.borrower_terdaftar_sejak_berdiri}
                />
                <CardMultiColumn 
                  text={`Jumlah Borrower Terdaftar (${new Date().getFullYear()})`}
                  api={data?.data?.data?.borrower_terdaftar_tahun_berjalan}
                />
                <CardMultiColumn 
                  text="Jumlah Borrower Aktif"
                  api={data?.data?.data?.borrower_aktif}
                />
              </div>
            </div>
          </div>
          <div>
          <div className='flex flex-col'>
            <div className="w-[30rem] rounded overflow-hidden shadow-md shadow-gray-00 text-center mx-11 my-11 font-portofolio p-7">
              <CardMultiColumn 
                text="Jumlah Lender Terdaftar (Sejak Berdiri)"
                api={data?.data?.data?.lender_terdaftar_sejak_berdiri}
              />
              <CardMultiColumn 
                text={`Jumlah Lender Terdaftar (${new Date().getFullYear()})`}
                api={data?.data?.data?.lender_terdaftar_tahun_berjalan}
              />
              <CardMultiColumn 
                text="Jumlah Lender Aktif"
                api={data?.data?.data?.lender_aktif}
              />
            </div>
          </div>
          </div>
        </div> */}

        <div className='flex flex-row ml-12'>

          <div className='flex flex-col flex-1 rounded overflow-hidden shadow-md shadow-gray-00 text-center mx-11 my-11 font-portofolio p-7'>

            <CardMultiColumn 
              text="Jumlah Borrower Terdaftar (Sejak Berdiri)"
              api={data?.data?.data?.borrower_terdaftar_sejak_berdiri}
            />
            <CardMultiColumn 
              text={`Jumlah Borrower Terdaftar (${new Date().getFullYear()})`}
              api={data?.data?.data?.borrower_terdaftar_tahun_berjalan}
            />
            <CardMultiColumn 
              text="Jumlah Borrower Aktif"
              api={data?.data?.data?.borrower_aktif}
            />

          </div>

          <div className='flex flex-col flex-1 rounded overflow-hidden shadow-md shadow-gray-00 text-center mx-11 my-11 font-portofolio p-7'>

            <CardMultiColumn 
              text="Jumlah Lender Terdaftar (Sejak Berdiri)"
              api={data?.data?.data?.lender_terdaftar_sejak_berdiri}
            />
            <CardMultiColumn 
              text={`Jumlah Lender Terdaftar (${new Date().getFullYear()})`}
              api={data?.data?.data?.lender_terdaftar_tahun_berjalan}
            />
            <CardMultiColumn 
              text="Jumlah Lender Aktif"
              api={data?.data?.data?.lender_aktif}
            />

          </div>

        </div>

      </div>
    </>
  );
}

export default Portofolio