import { motion } from "framer-motion";
import React from "react";
import { useEffect, useState } from 'react';
// import { tkbData } from '../services/data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faRectangleXmark } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from "react-router-dom";
import TkbCarousel from "./TkbCarousel";
import "../styles/index.css";

//Framer Variants
const login = {
  hover: {
    backgroundColor: "#FF0049",
    color: "#FFFFFF",
    borderColor: "#FFFFFF",
    transition: {
      duration: 0.3,
    },
  },
  start: {
    backgroundColor: "#FFFFFF",
    color: "#FF0049",
    borderColor: "#FF0049",
  },
};

const button = {
  hover: {
    scale:1.1,
    backgroundColor: "#FFFFFF",
    color: "#FF0049",
    borderColor: "#FF0049",
    transition: {
      duration: 0.3,
    },
  },
};

const register = {
  hover: {
    backgroundColor: "#FFFFFF",
    color: "#FF0049",
    borderColor: "#FF0049",
    transition: {
      duration: 0.3,
    },
  },
  start: {
    backgroundColor: "#FF0049",
    color: "#FFFFFF",
    borderColor: "#FFFFFF",
  },
};

const menu = {
  hover: {
    x:100,
    scale:"1.2",
    color: "#FF0049",
    textDecoration: "underline",
    textUnderlineOffset: "6px",
    textDecorationThickness: "2px",
    transition: {
      duration: 0.3,
    },
  },
};

const Navbar = () => {
  const [data, setData] = useState([])
  const [navOpen, setNavOpen] = useState(false);
  // const [displayedTkb, setdisplayedTkb] = useState(0)
  const location = useLocation();
  useEffect(() => {
    // api()
  }, [])

  // const api = async () => {
  //   const tes = await tkbData()
  //   setData(tes)
  // }

  // const changeDisplayedTkb = (tabId) => {

  //   setdisplayedTkb(tabId)

  // }

  return (
    <div className="font-navbar sticky top-0 z-[60] bg-white p-[2rem] shadow-lg shadow-black-500/50">
      <nav className="lg:flex justify-center font-bold transition-all duration-500 ease-in">
        <div className="sm:flex">
          <a href="/">
            <img  className="mr-4 w-[14rem]" src={require("../images/logo-3 1.png")} alt="images" />
          </a>
          <img className="w-[8rem] h-auto lg:my-0 my-2 mr-10"  src={require("../images/Otoritas Jasa Keuangan.png")} alt="images" />
        </div>

        <div
          onClick={() => {
            setNavOpen(!navOpen);
          }}
          className="text-2xl absolute right-8 top-12 cursor-pointer lg:hidden"
        >
          <FontAwesomeIcon icon={navOpen ? faRectangleXmark : faBars} />
        </div>
        <ul
          className={`lg:flex lg:flex-row flex flex-col space-x-7 lg:items-center mx-5 lg:pl-0 pl-12 lg:pb-0 pb-10 text-[#878787] text-[1rem] absolute lg:static bg-white lg:z-auto z-[-1] -left-5 w-full lg:w-auto ${
            navOpen ? "top-50" : "top-[-1000px]"
          }`}
        >
          <li className="lg:mx-2 lg:my-0 my-2">
            {/* <a href="./ajukan-pinjaman">Ajukan Pinjaman</a> */}
            {/* <motion.div whileHover={{ scale: 1.1 }}>
              <motion.a
                href="./ajukan-pinjaman"
                whileHover="hover"
                variants={menu}
              >
                Ajukan Pinjaman
              </motion.a>
            </motion.div> */}
            {location.pathname === "/ajukan-pinjaman" ? (
              <a href="/ajukan-pinjaman" className="text-[#FF0049]">
                Ajukan Pinjaman
              </a>
            ) : (
              <motion.div whileHover={{ scale: 1.1 }}>
                <motion.a
                  href="./ajukan-pinjaman"
                  whileHover="hover"
                  variants={menu}
                >
                  Ajukan Pinjaman
                </motion.a>
              </motion.div>
            )}
          </li>          
          <li className="lg:mx-2 lg:my-0 my-2">
            {/* <a href="./beri-pinjaman">Beri Pinjaman</a> */}
            {/* <motion.a href="./beri-pinjaman" whileHover="hover" variants={menu}>
              Beri Pinjaman
            </motion.a> */}
            {location.pathname === "/beri-pinjaman" ? (
              <a href="/beri-pinjaman" className="text-[#FF0049]">
                Beri Pinjaman
              </a>
            ) : (
              <motion.div whileHover={{ scale: 1.1 }}>
                <motion.a
                  href="./beri-pinjaman"
                  whileHover="hover"
                  variants={menu}
                >
                  Beri Pinjaman
                </motion.a>
              </motion.div>
            )}
          </li>

          <li className="lg:mx-2 lg:my-0 my-2">
            {/* <a href="./tentang-kami">Tentang Kami</a> */}
            {/* <motion.div whileHover={{ scale: 1.1 }}>
              <motion.a
                href="./tentang-kami"
                whileHover="hover"
                variants={menu}
              >
                Tentang Kami
              </motion.a>
            </motion.div> */}
            {location.pathname === "/tentang-kami" ? (
              <a href="/tentang-kami" className="text-[#FF0049]">
                Tentang Kami
              </a>
            ) : (
              <motion.div whileHover={{ scale: 1.1 }}>
                <motion.a
                  href="./tentang-kami"
                  whileHover="hover"
                  variants={menu}
                >
                  Tentang Kami
                </motion.a>
              </motion.div>
            )}
          </li>

          <li className="lg:mx-2 lg:my-0 my-2">

            {location.pathname === "/legal-faq" ? (
              <a href="/legal-faq" className="text-[#FF0049]">
                Legal & FAQ
              </a>
            ) : (
              <motion.div whileHover={{ scale: 1.1 }}>
                <motion.a
                  href="./legal-faq"
                  whileHover="hover"
                  variants={menu}
                >
                  Legal & FAQ
                </motion.a>
              </motion.div>
            )}
          </li>          

          {/* <li className="lg:mx-3 lg:my-0 my-5">
            <motion.div whileHover={{ scale: 1.1 }}>
              <motion.a
                href="/login"
                className="py-3 px-9 rounded-3xl border text-sm"
                initial="start"
                whileHover="hover"
                variants={login}
              >
                {" "}
                LOGIN
              </motion.a>
            </motion.div>
          </li> */}
          
          <li className="lg:mx-3 lg:my-0 my-5">
            <motion.div whileHover={{ scale: 1.1 }}>
              <motion.a
                href="/login"
                className="bg-[#F14651] border py-3 px-7 rounded-[50px] font-bold text-white"
                initial="start"
                whileHover="hover"
                variants={button}
              >
                Masuk
              </motion.a>
            </motion.div>
          </li>

          {/* <li>
            <div className="flex flex-col justify-center font-semibold ml-1 text-black">
              <p className="text-sm font-semibold">TKB90</p>
              <p className="text-xl font-semibold">{data?.data?.tkb}</p>
              <div className="flex flex-row justify-center font-semibold ml-1 text-black">
              <div style={cursorPointer}>
                <div style={underlineActive}></div>
              </div>
              <div style={cursorPointer}>
                <div style={underlineInactive}></div>
              </div>
              </div>
            </div>
          </li> */}

          <li>
            <TkbCarousel />
          </li>

        </ul>
      </nav>
    </div>
  );
};

export default Navbar;

// px-20 py-4