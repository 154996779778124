import React from "react";
import { useEffect, useState } from 'react';
import { tkbData } from '../services/data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'react-tooltip';

const cursorPointer = {
    cursor: "default",
    paddingBottom: "0.5rem",
    paddingTop: "0.25rem",
    display: "block",
    boxSizing: "border-box"
}

const underlineActive = {
    cursor: "default",
    background: "red",
    width: "20px",
    height: "5px",
    borderRadius: "100px",
    marginRight: "0.80rem"
}

const underlineInactive = {
    cursor: "pointer",
    background: "#e0e0e0",
    width: "20px",
    height: "5px",
    borderRadius: "100px",
    marginRight: "0.80rem"
}

const TkbCarousel = () => {
    const [data, setData] = useState({})
    const [displayedTkb, setdisplayedTkb] = useState(0)

    useEffect(() => {
        const intervalTkb = setInterval(() => {
            const toBeDisplayed = (displayedTkb == 3) ? 0 : (displayedTkb + 1);
            setdisplayedTkb(toBeDisplayed);
        }, 5000);
    
        return () => clearInterval(intervalTkb);
    
    }, [displayedTkb])

    useEffect(() => {
        api()
    }, [])
    
    const api = async () => {
        const tes = await tkbData()
        setData(tes)
    }
    
    const changeDisplayedTkb = (tabId) => setdisplayedTkb(tabId);

    return (
        <>
            <div className="flex flex-col  font-semibold ml-1 text-black">
                {
                (displayedTkb == 0) && (
                    <a data-tooltip-id="tkb-tooltip" style={{cursor: "pointer"}} data-tooltip-content={"90 (sembilan puluh)"}>
                    <p className="text-xs font-semibold">TKB90 <FontAwesomeIcon icon={faCircleInfo} /></p>
                    <p className="text-lg font-semibold">{data?.data?.tkb90}</p>
                    </a>
                )
                }

                {
                (displayedTkb == 1) && (
                    <a data-tooltip-id="tkb-tooltip" style={{cursor: "pointer"}} data-tooltip-content={"60 (enam puluh)"}>
                    <p className="text-xs font-semibold">TKB60 <FontAwesomeIcon icon={faCircleInfo} /></p>
                    <p className="text-lg font-semibold">{data?.data?.tkb60}</p>
                    </a>
                ) 
                }

                {
                (displayedTkb == 2) && (
                    <a data-tooltip-id="tkb-tooltip" style={{cursor: "pointer"}} data-tooltip-content={"30 (tiga puluh)"}>
                    <p className="text-xs font-semibold">TKB30 <FontAwesomeIcon icon={faCircleInfo} /></p>
                    <p className="text-lg font-semibold">{data?.data?.tkb30}</p>
                    </a>
                )
                }

                {
                (displayedTkb == 3) && (
                    <a data-tooltip-id="tkb-tooltip" style={{cursor: "pointer"}} data-tooltip-content={"0 (nol)"}>
                    <p className="text-xs font-semibold">TKB0 <FontAwesomeIcon icon={faCircleInfo} /></p>
                    <p className="text-lg font-semibold">{data?.data?.tkb0}</p>
                    </a>
                )
                }

                <Tooltip
                id="tkb-tooltip"
                render={({ content, activeAnchor }) => (
                    <span>
                    Ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan {content} hari kalender terhitung sejak jatuh tempo
                    </span>
                )}
                offset={30} style={{ backgroundColor: "rgb(241, 70, 81)", color: "rgb(255, 255, 255)", width: "18rem", borderRadius: "5px" }}
                opacity={1}
                />
            </div>

            <div className="flex flex-row justify-center font-semibold ml-1 text-black">
                <div style={cursorPointer}>
                    <div {...((displayedTkb == 0) ? {style: underlineActive} : {style: underlineInactive})}onClick={() => changeDisplayedTkb(0)}></div>
                </div>
                <div style={cursorPointer}>
                    <div {...((displayedTkb == 1) ? {style: underlineActive} : {style: underlineInactive})} onClick={() => changeDisplayedTkb(1)}></div>
                </div>
                <div style={cursorPointer}>
                    <div {...((displayedTkb == 2) ? {style: underlineActive} : {style: underlineInactive})} onClick={() => changeDisplayedTkb(2)}></div>
                </div>
                <div style={cursorPointer}>
                    <div {...((displayedTkb == 3) ? {style: underlineActive} : {style: underlineInactive})} onClick={() => changeDisplayedTkb(3)}></div>
                </div>
            </div>
        </>
    )
}

export default TkbCarousel