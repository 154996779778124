import { useEffect, useState } from "react";

const WarningPopup = () => {

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const popupShown = localStorage.getItem('popupShown');
    if (!popupShown) {
      setShowPopup(true);
      localStorage.setItem('popupShown', true);
    }
    console.log(popupShown)
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && (
        <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative mt-15 w-3/5 bg-white p-6 rounded-lg shadow-lg text-center">
            <button
              onClick={closePopup}
              className="absolute top-2 right-5 text-gray-500 hover:text-gray-700 text-xl"
            >
              &times;
            </button>
            <img className="mx-auto" src={require('../images/logo-3 1.png')} alt="logo-iki" /><br/>
            <h2 className="text-2xl font-bold mb-4 text-red-600">PERINGATAN :</h2><br/>
            <p className="mb-10 text-center">
            HATI-HATI, TRANSAKSI INI  BERISIKO TINGGI. ANDA DAPAT SAJA MENGALAMI KERUGIAN ATAU KEHILANGAN  UANG. JANGAN BERUTANG JIKA TIDAK MEMILIKI KEMAMPUAN MEMBAYAR.  PERTIMBANGKAN SECARA BIJAK SEBELUM BERTRANSAKSI.
            </p>
            <p className="mb-4 text-sm text-center">
              IKI Modal adalah platform P2P Lending yang telah berizin dan diawasi oleh Otoritas Jasa Keuangan. Kami menghimbau untuk WASPADA terhadap segala bentuk informasi yang mengatasnamakan PT IKI Karunia Indonesia (IKI Modal).<br /><br />
              Cek kebenaran informasi yang kamu terima melalui kontak resmi kami:<br/>
              Call Center (Whatsapp) : 081211117359<br/>
              Email : helpdesk@iki.id<br/>
              marketing@iki.id 
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default WarningPopup