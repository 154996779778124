import React from 'react'
import "../styles/index.css"
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { useState } from 'react';

const Card = (props) => {
  const [active,setActive] = useState(true)

  const {text,api} = props;
  const handleSensor = () =>{
    // if (isNaN(api)) return 0
    if (typeof api == "string" && api.includes("%")) return api
    else if (typeof api == "string") return api
    else return <CountUp end={api} duration={2} onStart={() => setActive(false) } />;
  }
  return (
    <div className="w-[25rem] rounded overflow-hidden shadow-md shadow-gray-00 text-center mx-11 my-11 font-portofolio p-7">
      {/* <p className='my-2 font-semibold text-[33px]'>{api}</p> */}
      <VisibilitySensor active={active}>
        {({ isVisible }) => (
          <p className="my-2 font-semibold text-[33px]">
            {isVisible ? handleSensor() : 0}
          </p>
        )}
      </VisibilitySensor>
      <p className="font-light">{text}</p>
    </div>
  );
}

export default Card