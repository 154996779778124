import React from 'react'
import CardVertical from '../../component/CardVertical';

const PortofolioSubContent = (data) => {
  return (
    
      <div className="flex lg:flex-row flex-col justify-center space-x-20">
        <div className="flex flex-col rounded shadow-md shadow-gray-00 text-center font-portofolio w-[25rem] md:mx-0 mx-auto p-5">
          <CardVertical
            title='Nilai Pinjaman Tersalurkan (Sejak Berdiri)'
            api={data?.data?.data?.pinjaman_tersalurkan_sejak_berdiri}
          />
          <CardVertical
            title="Nilai yang harus dikembalikan MIN"
            api={data?.data?.data?.pengembalian_terendah}
          />
          <CardVertical
            title="Total biaya MIN"
            api={data?.data?.data?.biaya_terendah}
          />
          {/* <CardVertical
            title="Presentase biaya harian MIN"
            api={data?.data?.data?.persen_biaya_rendah}
          /> */}
        </div>
        <div className="flex flex-col rounded shadow-md shadow-gray-00 text-center font-portofolio w-[25rem] md:mx-0 mx-auto md:my-0 my-5 p-5">
          <CardVertical
            title={`Nilai Pinjaman Tersalurkan (${new Date().getFullYear()})`}
            api={data?.data?.data?.pinjaman_tersalurkan_tahun_berjalan}
          />
          <CardVertical
            title="Nilai yang harus dikembalikan MAX"
            api={data?.data?.data?.pengembalian_tertinggi}
          />
          <CardVertical
            title="Total biaya MAX"
            api={data?.data?.data?.biaya_tertinggi}
          />
          {/* <CardVertical
            title="Presentase biaya harian MAX"
            api={data?.data?.data?.persen_biaya_tinggi}
          /> */}
        </div>
      </div>
    
  );
}

export default PortofolioSubContent;