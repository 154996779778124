import { motion } from 'framer-motion';
import React from 'react'
import "../styles/index.css"
import { useLocation } from "react-router-dom";

const button = {
  hover: {
    scale:1.1,
    backgroundColor: "#FFFFFF",
    color: "#FF0049",
    borderColor: "#FF0049",
    transition: {
      duration: 0.3,
    },
  },
};

const SubheaderContent = (props) => {
  const {content} = props;
  const location = useLocation();
  console.log(content);
  console.log(location.pathname)
  console.log(content[0].data.length)
  return(
    <>
    {content.map((item,i) =>{
      return (
        <div
          className="container mx-auto flex flex-col items-center my-20 font-body"
          key={i}
        >
          <h4 className="font-bold text-center text-[41px]">{item.title}</h4>
          <hr className="border-black border-[2px] w-[50px] rounded my-5"></hr>
          <p className="text-[18px] text-primary">{item.subtitle}</p>
          <div
            className={`grid md:grid-cols-${content[0].data.length} sm:grid-cols-2 grid-cols-1 my-9`}
          >
            {item.data.map((content, j) => {
              return (
                <div
                  className="container items-center text-center w-[13rem] mx-7"
                  key={j}
                >
                  {/* <img
                    className="h-20 w-20 mx-auto my-6"
                    src={content.image}
                    alt=""
                  ></img> */}
                  <picture>
                    <source type='image/webp' className='h-20 w-20 mx-auto my-6' srcSet={content.imageWebp}></source>
                    <source type='image/png' className='h-20 w-20 mx-auto my-6' srcSet={content.imagePng}></source>
                    <img src={content.imagePng} className='h-20 w-20 mx-auto my-6'></img>
                  </picture>
                  <p className="font-bold text-[1.2rem] my-4">
                    {" "}
                    {content.header}
                  </p>
                  <p className="font-light text-[1rem] text-primary">
                    {content.body}
                  </p>
                </div>
              );
            })}
          </div>
          {
            location.pathname === "/ajukan-pinjaman" ? (
              <motion.a
                href={process.env.REACT_APP_BORROWER_START_URL}
                whileHover="hover"
                variants={button}
                className="bg-[#F14651] border py-3 px-7 rounded-[50px] font-bold text-white"
              >
                Ajukan Pinjaman
              </motion.a>
            ) : (
              <motion.a
                href={process.env.REACT_APP_LENDER_START_URL}
                whileHover="hover"
                variants={button}
                className="bg-[#F14651] border py-3 px-7 rounded-[50px] font-bold text-white"
              >
                Beri Pinjaman
              </motion.a>
            )
            // <a href="./login" className="bg-[#F14651] py-3 px-7 rounded-[50px] font-bold text-white">Ajukan Pinjaman</a>
            // : <a href="./login" className="bg-[#F14651] py-3 px-7 rounded-[50px] font-bold text-white">Beri Pinjaman</a>
          }
        </div>
      );
    })}
    </>
  )
}
        
export default SubheaderContent