import { motion } from 'framer-motion';
import Iframe from 'react-iframe'
import { useEffect, useState } from 'react';
import { laporanKeuangan, laporanLabaRugiKomprehensif } from '../services/data'
import AboutUsPopup from '../component/AboutUsPopup';
// import NumberFormat from 'react-number-format';
// import { rupiah } from '../util/function'

const variant = {
  hover: {
    backgroundColor: "#FF0049",
    color: "#FFFFFF",
    borderColor: "#FFFFFF",
    transition: {
      duration: 0.3,
    },
  },
};


const AboutUs = () => {
  
  const [laporanKeu, setLapKeu] = useState()
  const [laporanLabaRugi, setLapLabaRugi] = useState()

  // About Us Popup
  const [selectedPopups, setSelectedPopups] = useState()
  const popupContent = [
    {
      content : {
        urlJpg : require("../images/Director/webp/pak_wira.webp"),
        name: "Wira Nugraha",
        position: "Operational Director",
        description: "Warga negara Indonesia, lahir pada Oktober 1980. Pengalaman kerja antara lain PT Asia Pacific Ventura sebagai Manajer Manajemen Risiko (2020-2022), PT Karunia Multifinance sebagai Credit Review & Support Spv (2019-2020), BPR Bahtera Masyarakat Jabar sebagai PE Satuan Pengawas Internal (SPI) (2017-2018), BPR Bahtera Masyarakat Jabar sebagai Analis Kredit (2016-2017), Bank Pundi sebagai Team Leader Credit Review (2013-2016), Bank Pundi sebagai Credit Review & Appraisal (2012-2013), Bank Danamon sebagai Credit Officer (2011-2012), Bank Danamon sebagai Sales Officer (2010-2011), Bank Danamon sebagai Teller (2009-2010), Bank Danamon sebagai Relationship Officer (2008-2009)."
      }
    },
    {
      content : {
        urlJpg : require("../images/Director/webp/pak_joen.webp"),
        name: "Joen Sianto Chandra",
        position: "President Director",
        description: "Warga negara Indonesia, lahir pada Oktober 1972. Pengalaman kerja antara lain PT Adicipta Dinamika Inovasi sebagai Director of Sales (2014-2018), PT Bhakti Wasantara Net sebagai Direktur Komersial (2013- 2014), PT Maxtrinet Global Indonesia sebagai Direktur (2012-2013), PT Sprint Asia Technology sebagai Pengembang Bisnis dan Direktur Operasional (2012– 2013)."
      }
    },
    {
      content : {
        urlJpg : require("../images/Director/webp/pak_adriansyah.webp"),
        name: "Adriansyah Adnan",
        position: "President Comissioner",
        description: "Warga Negara Indonesia, lahir pada November 1970. Pengalaman kerja sebelumnya antara lain sebagai General Manager di PT Anabatic Technologies (2002-2005), Project Manager di PT GlobalTech Computer Konsulindo (2001-2002), Senior Consultant di PT Pradana Consulting (2000-2001), Project Manager di PT Visi Bahana Indonesia (1996-1999), dan Database & Statistic Engineer di PT Schneider Ometraco (1994-1996)."
      }
    },
    {
      content : {
        urlJpg : require("../images/Director/webp/pak_victor.webp"),
        name: "Victor Budi Tanuadji",
        position: "Comissioner",
        description: "Warga negara Indonesia, lahir pada Juli 1985. Pengalaman kerja PT Asia Pacific Ventura sebagai Direktur (2019-sekarang) dan pekerjaan sebelumnya antara lain PT Bank CIMB Niaga sebagaiRelationship Manager (2011-2013), Evalueserve, Shanghai, China sebagai Analyst Investment Research (2010-2011), State Street Corporation, North Quincy, MA, USA sebagai Dividends Analyst (2008-2009), Millenium Partnerand Sports Club Management, LLC, Boston, MA, USA sebagai Financial Analyst (2008)."
      }
    },
  ];
  
  const handleImageClick = (content) => {
    setSelectedPopups(content);
  };

  const closePopup = () => {
    setSelectedPopups(null);
  };

  
  useEffect(() => {
    api()
  }, [])

  const api = async () => {
    const lapKeu = await laporanKeuangan()
    const lapLabaRugi = await laporanLabaRugiKomprehensif()
    setLapKeu(lapKeu);
    setLapLabaRugi(lapLabaRugi);
  }
  const container = {
    hidden: { opacity: 0, scale: 0.5 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5
      },
      scale: 1
    }
  }
  // console.log(laporanKeu);
  // console.log(laporanLabaRugi);

  return (
    <>
      <div className="flex md:flex-row flex-col-reverse justify-around py-10 md:pl-0 pl-10 bg-[#FFE9E9]">
        <motion.div
          className="md:w-[40%] w-[90%]"
          transition={{
            duration: 0.5,
            type: "tween",
          }}
          variants={container}
          initial="hidden"
          animate="show"
        >
          <h2 className="text-4xl font-bold">Tentang Kami</h2>
          <p className="font-light leading-7 mt-5">
            IKI Modal merupakan solusi platform pinjaman peer to-peer dari PT
            IKI Karunia Indonesia, yang berdiri pada Juli 2019 dan telah
            mendapatkan status berizin dari Otoritas Jasa Keuangan pada Agustus
            2021. IKI Modal juga telah menjadi member Asosiasi Fintech Pendanaan
            Bersama Indonesia (AFPI). IKI modal menghubungkan perorangan ataupun
            badan usaha yang membutuhkan pinjaman dengan pemberi pinjaman dengan
            menyediakan marketplace pinjaman. Market place pinjaman ini juga
            memberikan kebebasan bagi pemberi pinjaman untuk menyalurkan dana
            yang dimiliki sesuai dengan return yang diharapkan.
          </p>
          <br />
          <p className="font-light leading-8">
            IKI Modal juga didirikan dan didukung oleh PT Anabatic Technologies
            Tbk, sebagai salah satu perusahaan IT terkemuka di Indonesia serta
            oleh tim dengan pengalaman finasial yang mumpuni
          </p>
          <div className="flex justify-around sm:flex-row flex-col my-5">
            <div className="flex flex-col">
              <h4 className="font-bold">Berizin dan diawasi oleh</h4>
              <img
                src={require("../images/Otoritas Jasa Keuangan.png")}
                className="mt-3 w-[10rem]"
                alt="OJK"
              />
            </div>
            <div className="flex flex-col sm:mt-0 mt-5">
              <h4 className="font-bold">Anggota terdaftar dari</h4>
              <img
                src={require("../images/Asset Icons Website IKI Modal/Group 600.png")}
                className="sm:mx-auto mt-3 w-[10rem]"
                alt="Afpi"
              />
            </div>
            <div className="sm:mt-0 mt-5">
              <h4 className="font-bold">Telah tersertifikasi</h4>
              <img
                src={require("../images/Asset Icons Website IKI Modal/pngegg-2.png")}
                className="w-[5rem] sm:mx-auto mt-3"
                alt="Iso Certificate"
              />
            </div>
          </div>
          {/* <div className="my-10 flex sm:flex-row flex-col sm:justify-start justify-between">
            <a
              href=""
              className="bg-white hover:bg-gray-100 text-black py-2 px-4 rounded drop-shadow-sm	w-[8.5rem] sm:w-[auto]"
            >
              Data Pinjaman
            </a>
            <a
              href=""
              className="bg-black hover:bg-stone-700 rounded py-2 px-4 text-white sm:ml-5 sm:mt-0 mt-5  drop-shadow-sm w-[8.5rem] sm:w-[auto]"
            >
              Hubungi Kami
            </a>
          </div> */}
        </motion.div>

        <div className="md:my-0 my-10 md:w-[600px] w-[90%] h-[400px] border-4 rounded-sm">
          <Iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.0626689504497!2d106.61763465062366!3d-6.255474562966153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fc7a8facbe19%3A0x16699d670e8909b1!2sGraha%20Anabatic!5e0!3m2!1sid!2sid!4v1650956879017!5m2!1sid!2sid"
            width="100%"
            height="100%"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
          />
        </div>
      </div>
      
      <div className="w-[60%] my-20 mx-auto">
        <p className="font-bold text-[41px] text-center font-body">Tim Kami</p>
        <hr className="border-black border-[2px] w-[50px] rounded my-5 mx-auto"></hr>
        <p className="text-center font-light">
          Berikut merupakan jajaran direksi, komisaris dan pemegang saham kami.
        </p>
        <p className="font-bold text-[32px] text-center font-body my-10">Direksi</p>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 place-items-center my-10">

          <div className="xl:w-[20rem] lg:w-[17rem] w-[15rem] mb-5">
            <img
              src={require("../images/Director/webp/pak_wira.webp")}
              className="mx-auto"
              alt=""
              onClick={() => handleImageClick(popupContent[0])}
            />
            <p  onClick={() => handleImageClick(popupContent[0])} className="mt-5 flex flex-row justify-center items-center text-gray-600 underline decoration-solid font-semibold text-[20px]"> 
              Wira Nugraha
            </p>
            <p className="mt-0 flex flex-row justify-center items-center text-red-500 font-semibold text-[18px]"> 
              Operational Director
            </p>
            
          </div>

          <div className="xl:w-[20rem] lg:w-[17rem] w-[15rem] mb-5">
            <img
              src={require("../images/Director/webp/pak_joen.webp")}
              className="mx-auto"
              alt=""
              onClick={() => handleImageClick(popupContent[1])}
            />
            
            <p  onClick={() => handleImageClick(popupContent[1])} className="mt-5 flex flex-row justify-center items-center text-gray-600 underline decoration-solid font-semibold text-[20px]"> 
              Joen Sianto Chandra
            </p>
            <p className="mt-0 flex flex-row justify-center items-center text-red-500 font-semibold text-[18px]"> 
              President Director
            </p>
          </div>

        </div>
        <p className="font-bold text-[32px] text-center font-body my-10">Dewan Komisaris</p>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 place-items-center my-10">

          <div className="xl:w-[20rem] lg:w-[17rem] w-[15rem] mb-5">
            <img
              src={require("../images/Director/webp/pak_adriansyah.webp")}
              className="mx-auto"
              alt=""
              onClick={() => handleImageClick(popupContent[2])}
            />
            <p  onClick={() => handleImageClick(popupContent[2])} className="mt-5 flex flex-row justify-center items-center text-gray-600 underline decoration-solid font-semibold text-[20px]"> 
              Adriansyah Adnan
            </p>
            <p className="mt-0 flex flex-row justify-center items-center text-red-500 font-semibold text-[18px]"> 
              President Commissioner
            </p>
            
          </div>

          <div className="xl:w-[20rem] lg:w-[17rem] w-[15rem] mb-5">
            <img
              src={require("../images/Director/webp/pak_victor.webp")}
              className="mx-auto"
              alt=""
              onClick={() => handleImageClick(popupContent[3])}
            />
            
            <p  onClick={() => handleImageClick(popupContent[3])} className="mt-5 flex flex-row justify-center items-center text-gray-600 underline decoration-solid font-semibold text-[20px]"> 
              Victor Budi Tanuadji
            </p>
            <p className="mt-0 flex flex-row justify-center items-center text-red-500 font-semibold text-[18px]"> 
              Commissioner
            </p>
          </div>
        </div>
          <p className="font-bold text-[32px] text-center font-body my-10">Pemegang Saham</p>
          <p className="text-center text-[24px] font-normal">
            PT	Emporia	Digital	Raya <br />
            <p class="text-base text-justify px-20 my-5">PT Emporia Digital Raya (Emporia) adalah suatu Perusahaan terbatas yang berkedudukan di Kabupaten Tangerang, didirikan di tahun 2015. Emporia melakukan kegiatan usahanya sebagai holding company untuk perusahaan-perusahaan anak yang beroperasi di bidang financial technology. Fungsi utama yang dilakukan oleh Emporia adalah untuk mendukung, menyelaraskan dan mengoptimalkan kegiatan usaha perusahaan anak dengan memberikan dukungan teknologi, pengembangan platform, dukungan finansial, serta akses pasar yang lebih luas</p>
            PT	Sinergi	Karya	Infolestari <br />
            <p class="text-base text-justify px-20 my-5">PT SKIL adalah perusahaan yang didirikan tahun 2015 dan melakukan kegiatan usahanya berfokus pada bidang investasi perusahaan perdagangan yang berkaitan dengan bahan-bahan tambang dan teknologi finansial</p>
            Vanguard	Technology	Nusa	Pte.,	Ltd <br />
            <p class="text-base text-justify px-20 my-5">VANGUARD TECHNOLOGY NUSA PTE LTD (VANGUARD) adalah suatu Perusahaan terbatas yang berkedudukan di Singapura dan bergerak di bidang Konsultasi Informasi Teknologi.</p>
          </p>
      </div>
      <div className="my-20">
        <p className="font-bold text-[41px] text-center font-body">
          Laporan Keuangan
        </p>
        <hr className="border-black border-[2px] w-[50px] rounded my-5 mx-auto"></hr>
        <p className="text-center font-light">
          Berikut merupakan preview laporan keuangan kami.
        </p>

        <div className="justify-center mx-auto max-w-5xl mt-16 z-30">
          <div class="flex flex-col">
            <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-hidden">
                  <table class="min-w-full ">
                    
                    <tbody>

                      <tr class=" border-y-2 border-gray-400">
                        <td class="px-6 py-4 whitespace-nowrap text-normal font-medium">
                          Laporan Keuangan 2020 PT IKI Karunia Indonesia.pdf
                          <br/>
                          <span className="text-sm text-gray-500">PDF Document</span> 
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            <a href="http://ikimodal-prod1.oss-ap-southeast-5.aliyuncs.com/0cQz7sphrifxANz.pdf" target='_blank' download>
                              <button className="p-2 font-semibold drop-shadow-md bg-slate-100">Download</button>
                            </a>
                        </td>


                      </tr>
                      <tr class=" border-y-2 border-gray-400">
                        <td class="px-6 py-4 whitespace-nowrap text-normal font-medium">
                          Laporan Keuangan 2021 PT IKI Karunia Indonesia.pdf
                          <br/>
                          <span className="text-sm text-gray-500">PDF Document</span> 
                        </td>

                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">

                          <div className="">
                            <a href="http://ikimodal-prod1.oss-ap-southeast-5.aliyuncs.com/WsWCE98q87Z08dO.pdf" target='_blank' download>
                              <button className="p-2 font-semibold drop-shadow-md bg-slate-100">Download</button>
                            </a>
                          </div>
                         
                        </td>
                      </tr>
                      <tr class=" border-y-2 border-gray-400">
                        <td class="px-6 py-4 whitespace-nowrap text-normal font-medium">
                          Laporan Keuangan 2022 PT IKI Karunia Indonesia.pdf
                          <br/>
                          <span className="text-sm text-gray-500">PDF Document</span> 
                        </td>

                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">

                          <div className="">
                            <a href="http://ikimodal-prod1.oss-ap-southeast-5.aliyuncs.com/1rUKpFfu9yr3cU1.pdf" target='_blank' download>
                              <button className="p-2 font-semibold drop-shadow-md bg-slate-100">Download</button>
                            </a>
                          </div>
                         
                        </td>
                      </tr>
                      <tr class=" border-y-2 border-gray-400">
                        <td class="px-6 py-4 whitespace-nowrap text-normal font-medium">
                          Laporan Keuangan 2023 PT IKI Karunia Indonesia.pdf
                          <br/>
                          <span className="text-sm text-gray-500">PDF Document</span> 
                        </td>

                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">

                          <div className="">
                            <a href="https://ikimodal-prod1.oss-ap-southeast-5.aliyuncs.com/Laporan%20Keuangan%202023%20PT%20IKI%20Karunia%20Indonesia.pdf" target='_blank' download>
                              <button className="p-2 font-semibold drop-shadow-md bg-slate-100">Download</button>
                            </a>
                          </div>
                         
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex justify-evenly my-16 lg:flex-row flex-col lg:px-[0] px-10">
          <div className="md:w-[35rem]">
            <h4 className="text-2xl font-medium">Laporan Keuangan</h4>
            <p className="font-light">Per 31 Januari 2022</p>
            <p className="text-sm opacity-50 italic">(dalam jutaan Rupiah)</p>
            <div className="relative overflow-x-auto my-5">
              <table className="2xl:w-[35rem] lg:w-[30rem] md:w-[25rem] sm:w-[25rem] w-[20rem] text-sm text-left text-black">
                <thead className="text-base uppercase">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      ASSET
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b border-gray-700 text-black">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap"
                    >
                      Asset Lancar
                    </th>
                    <td className="px-6 py-4 text-right">
                      <NumberFormat
                        prefix="Rp."
                        displayType="number"
                        className="text-right"
                        thousandSeparator={true}
                        value={laporanKeu?.data[0]?.asset_lancar}
                      />
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:border-gray-700 text-black">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap"
                    >
                      Asset Tidak Lancar
                    </th>
                    <td className="px-6 py-4 text-right">
                      <NumberFormat
                        prefix="Rp."
                        displayType="number"
                        className="text-right"
                        thousandSeparator={true}
                        value={laporanKeu?.data[0]?.asset_tidak_lancar}
                      />
                    </td>
                  </tr>
                  <tr className="bg-white text-black">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    ></th>
                    <td className="px-6 py-4 text-right text-base">
                      <NumberFormat
                        className="text-right"
                        prefix="Rp."
                        displayType="number"
                        thousandSeparator={true}
                        value={laporanKeu?.data[0]?.asset}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="relative overflow-x-auto">
              <table className="2xl:w-[35rem] lg:w-[30rem] md:w-[25rem] sm:w-[25rem] w-[20rem] text-sm text-left text-black">
                <thead className="text-base uppercase">
                  <tr>
                    <th scope="col" className="px-6 py-4">
                      Lialibilitas
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b border-gray-700 text-black">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap"
                    >
                      Lialibilitas Jangka Pendek
                    </th>
                    <td className="px-6 py-4 text-right">
                      <NumberFormat
                        prefix="Rp."
                        displayType="number"
                        className="text-right"
                        thousandSeparator={true}
                        value={laporanKeu?.data[0].lialibilitas_jangka_pendek}
                      />
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:border-gray-700 text-black">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap"
                    >
                      Lialibilitas Jangka Panjang
                    </th>
                    <td className="px-6 py-4 text-right">
                      <NumberFormat
                        prefix="Rp."
                        displayType="number"
                        className="text-right"
                        thousandSeparator={true}
                        value={laporanKeu?.data[0]?.lialibilitas_jangka_panjang}
                      />
                    </td>
                  </tr>
                  <tr className="bg-white text-black">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    ></th>
                    <td className="px-6 py-4 text-right text-base">
                      <NumberFormat
                        prefix="Rp."
                        displayType="number"
                        className="text-right"
                        thousandSeparator={true}
                        value={laporanKeu?.data[0]?.lialibilitas}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="relative overflow-x-auto">
              <table className="2xl:w-[35rem] lg:w-[30rem] md:w-[25rem] sm:w-[25rem] w-[20rem] text-sm text-left text-black lg:mb-0 mb-10">
                <thead className="uppercase text-base">
                  <tr className="border-b dark:border-gray-700">
                    <th scope="col" className="px-6 py-4">
                      Ekuitas
                    </th>
                    <th className="px-6 py-4 text-right">
                      <NumberFormat
                        prefix="Rp."
                        displayType="number"
                        className="text-right"
                        thousandSeparator={true}
                        value={laporanKeu?.data[0]?.ekuitas}
                      />
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div>
            <h4 className="text-2xl font-medium">
              Laporan Laba Rugi Komprehensif
            </h4>
            <p className="font-light">Per 31 Januari 2022</p>
            <p className="text-sm opacity-50 italic">(dalam jutaan Rupiah)</p>
            <div className="relative overflow-x-auto my-5">
              <table className="2xl:w-[40rem] lg:w-[30rem] md:w-[25rem] sm:w-[25rem] w-[20rem] text-sm text-left text-black">
                <tbody>
                  <tr className="bg-white border-b border-gray-700 text-black">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap"
                    >
                      Pendapatan
                    </th>
                    <td className="px-6 py-4 text-right">
                      <NumberFormat
                        prefix="Rp."
                        displayType="number"
                        className="text-right"
                        thousandSeparator={true}
                        value={laporanLabaRugi?.data[0]?.pendapatan}
                      />
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:border-gray-700 text-black">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap"
                    >
                      Beban Operasional
                    </th>
                    <td className="px-6 py-4 text-right">
                      <NumberFormat
                        prefix="Rp."
                        displayType="number"
                        className="text-right"
                        thousandSeparator={true}
                        value={laporanLabaRugi?.data[0]?.beban_operasional}
                      />
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:border-gray-700 text-black">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      Lain-lain
                    </th>
                    <td className="px-6 py-4 text-right">
                      <NumberFormat
                        prefix="Rp."
                        displayType="number"
                        className="text-right"
                        thousandSeparator={true}
                        value={laporanLabaRugi?.data[0]?.pendapatan}
                      />
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:border-gray-700 text-black">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      Rugi Komprehensif
                    </th>
                    <td className="px-6 py-4 text-right">
                      <NumberFormat
                        prefix="Rp."
                        className="text-right"
                        displayType="number"
                        thousandSeparator={true}
                        value={laporanLabaRugi?.data[0].rugi_komprehensif}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex justify-end w-[90%]">

          <motion.a
            variants={variant}
            whileHover="hover"
            href=""
            className="bg-white hover:bg-gray-100 py-2 px-2 rounded-[5px] text-red-500 border-solid border-2 border-red-500 drop-shadow-sm absolute"
          >
            Unduh Laporan Keuangan
          </motion.a>
        </div> */}
        
      </div>
      { selectedPopups && 
        // console.log(selectedPopups)
      (
        <AboutUsPopup content={selectedPopups.content} onClose={closePopup} />
      )}
    </>
  );
}

export default AboutUs