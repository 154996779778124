import { useEffect, useState } from 'react'
import SubheaderContent from '../component/SubheaderContent'
import { caraMemberikanPinjaman } from '../hardcoded/subheaderHardcode'
import Simulasi from './homepage/Simulasi';
import SubPilihanPinjaman from './homepage/SubPilihanPinjaman';
import { tkbData, pinjamanMarketplace } from "../services/data";

const BeriPinjaman = () => {
  const [data, setData] = useState([]);
  const [pinjaman, setPinjaman] = useState([]);

  useEffect(() => {
    apiTkb();
    apiPinjamanMarketplace();
  }, []);
  const apiTkb = async () => {
    const tes = await tkbData();
    setData(tes);
  };

  const apiPinjamanMarketplace = async () => {
    const data = await pinjamanMarketplace();
    setPinjaman(data);
  }
  return (
    <div>
      {/* <ImageSlider /> */}
      <SubheaderContent content={caraMemberikanPinjaman}/>
      {/* <SubPilihanPinjaman pinjaman={pinjaman} /> */}
      {/* <Simulasi/> */}
    </div>
  )
}

export default BeriPinjaman