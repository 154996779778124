import React from 'react'
import "../styles/index.css"
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { useState } from "react";

const CardVertical = (props) => {
   const [active, setActive] = useState(true);

   const handleSensor = () => {
    // if (isNaN(api)) return 0
    if (typeof api == "string" && api.includes("%")) return api
    else if (typeof api == "string") return api
    else return <CountUp end={api} duration={2} onStart={() => setActive(false) } />;
   };

    const {title,api} = props;
    return (
      <div className="my-1">
        <VisibilitySensor active={active}>
          {({ isVisible }) => (
            <p className="py-3 font-semibold text-[33px]">
              {isVisible ? handleSensor() : 0}
            </p>
          )}
        </VisibilitySensor>
        {/* <p className="py-3 font-semibold text-[33px]">{api}</p> */}
        <p className="text-[16px] font-light text-center break-words">{title}</p>
      </div>
    );
}

export default CardVertical