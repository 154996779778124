export const keutunganPendanaanPeminjam = [
  {
    title: "Keuntungan Pinjaman",
    subtitle:
      "Ajukan pinjaman anda sekarang dan rasakan keuntungan pinjaman di IKI Modal",
    data: [
      {
        // image: require("../images/Asset Icons Website IKI Modal/consultant-services.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/consultant-services.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/consultant-services.webp"),
        header: "Pengajuan Mudah",
        body: "Proses mudah & cepat secara online",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/settings-2.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/settings-2.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/settings-2.webp"),
        header: "Bunga Terjangkau",
        body: "Pinjaman dengan bunga pinjaman mulai dari 1% per bulan",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/administrative-fine.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/administrative-fine.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/administrative-fine.webp"),
        header: "Agunan Fleksibel",
        body: "Untuk mengajukan pinjaman di IKI Modal, Anda tidak perlu memiliki fixed asset sebagai agunan pinjaman",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/24-hours.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/shield-2.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/shield-2.webp"),
        header: "Keamanan Data",
        body: "Data Anda 100% aman dan terlindungi. Ajukan penghapusan data jika batal mengajukan pinjaman",
      },
    ],
  },
  {
    title: "Keuntungan Pendanaan",
    subtitle: "Bergabunglah dan rasakan pendanaan melalui IKI Modal",
    data: [
      {
        // image: require("../images/Asset Icons Website IKI Modal/discount.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/discount.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/discount.webp"),
        header: "Bunga Menarik",
        body: "Kembangkan Dana anda Bersama IKI Modal, dapatkan keuntungan bunga hingga 12% per tahun",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/shield-2.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/shield-2.png"),
        imageWeb: require("../images/Webp/Asset Icons Website IKI Modal/shield-2.webp"),
        header: "Mitigasi Terukur",
        body: "Dana anda terasuransi dengan asuransi kredit dan dengan pengembalian dana sebesar 90% (dana pokok)",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/easy-installation.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/easy-installation.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/easy-installation.webp"),
        header: "Mudah dan Cepat",
        body: "Seluruh proses registrasi secara online, proses pendistribusian dana dilakukan secara realtime dan termonitor",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/list.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/list.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/list.webp"),
        header: "Banyak Produk",
        body: "Tersedia banyak pilihan portofolio pinjaman yang sesuai dengan target dan preferensi anda",
      },
    ],
  }

];

export const caraMemberikanPinjaman = [
  {
    title: "Cara Memberikan Pinjaman",
    data: [
      {
        // image: require("../images/Asset Icons Website IKI Modal/registrasi.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/registrasi.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/registrasi.webp"),
        header: "Registrasi",
        body: "Melakukan pendaftaran melalui platform IKI Modal",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/digitalSignature.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/digitalSignature.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/digitalSignature.webp"),
        header: "Digital Signature",
        body: "Melakukan aktivasi digital signature melalui platform privy.id",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/verification-2.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/verification-2.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/verification-2.webp"),
        header: "Verifikasi",
        body: "Kami akan melakukan verifikasi data, setelah lolos proses verifikasi anda akan mendapatkan info melalui email, sms atau telp",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/marketplace.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/marketplace.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/marketplace.webp"),
        header: "Marketplace",
        body: "Pendana akan mendapatkan akses ke marketplace IKI modal, dan dapat memilih pinjaman yang sesuai untuk di danai",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/pencairan.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/pencairan.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/pencairan.webp"),
        header: "Pencairan",
        body: "Setelah pendana memilih pinjaman, maka IKI modal akan memfasilitasi pencairan pinjaman kepada peminjam.",
      },
    ],
  },
];

export const caraMengajukanPinjaman = [
  {
    title: "Cara Mengajukan Pinjaman",
    data: [
      {
        // image: require("../images/Asset Icons Website IKI Modal/registrasi.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/registrasi.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/registrasi.webp"),
        header: "Registrasi",
        body: "Melakukan pendaftaran melalui platform IKI Modal",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/digitalSignature.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/digitalSignature.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/digitalSignature.webp"),
        header: "Digital Signature",
        body: "Melakukan aktivasi digital signature melalui platform privy.id",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/verification-2.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/verification-2.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/verification-2.webp"),
        header: "Verifikasi",
        body: "IKI Modal melakukan verfikasi data dan kontak darurat, dan akan memberikan info melalui email, Sms atau telp apabila telah lolos proses verifikasi",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/analisa-kredit.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/analisa-kredit.png"),
        imageWebp:require("../images/Webp/Asset Icons Website IKI Modal/analisa-kredit.webp"),
        header: "Analisa Kredit",
        body: "IKI Modal akan melakukan analisa kredit dan memberikan scoring berdasarkan tingkat resiko pinjaman.",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/marketplace.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/marketplace.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/marketplace.webp"),
        header: "Marketplace",
        body: "Pinjaman anda akan masuk ke marketplace dan akan segera di danai oleh pemberi pinjaman",
      },
    ],
  },
];

export const produkPinjaman = [
  {
    title: "Produk Pinjaman",
    subtitle:
      "Tersedia Berbagai jenis produk pinjaman, silahkan pilih sesuai kebutuhan anda",
    data: [
      {
        // image: require("../images/Asset Icons Website IKI Modal/shield-3.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/shield-3.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/shield-3.webp"),
        header: "Pinjaman Karyawan",
        body: "Merupakan pinjaman yang diperuntukan bagi karyawan perusahaan yang telah terafiliasi  dengan IKI modal",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/document.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/document.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/document.webp"),
        header: "Invoice Financing",
        body: "Merupakan pinjaman modal kerja untuk perusahaan, dengan cara menjaminkan tagihan berjalan perusahaan ",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/list.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/list.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/list.webp"),
        header: "Pinjaman Produktif",
        body: "Merupakan pinjaman modal kerja untuk perusahaan, yang diperuntukan untuk meningkatkan produktifitas dan penghasilan",
      },
    ],
  },
];

export const keuntunganMeminjam = [
  {
    title: "Keuntungan Pinjaman",
    subtitle:"Ajukan pinjaman anda sekarang dan rasakan keuntungan pinjaman di IKI Modal",
    data: [
      {
        // image: require("../images/Asset Icons Website IKI Modal/consultant-services.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/consultant-services.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/consultant-services.webp"),
        header: "Pengajuan Mudah",
        body: "Proses mudah & cepat secara online",
        
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/settings-2.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/settings-2.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/settings-2.webp"),
        header: "Bunga Terjangkau",
        body: "Pinjaman dengan bunga pinjaman mulai dari 1% per bulan",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/administrative-fine.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/administrative-fine.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/administrative-fine.webp"),
        header: "Agunan Fleksibel",
        body: "Untuk mengajukan pinjaman di IKI Modal, Anda tidak perlu memiliki fixed asset sebagai agunan pinjaman",
      },
      {
        // image: require("../images/Asset Icons Website IKI Modal/24-hours.png"),
        imagePng: require("../images/JPG/Asset Icons Website IKI Modal/shield-2.png"),
        imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/shield-2.webp"),
        header: "Keamanan Data",
        body: "Data Anda 100% aman dan terlindungi. Ajukan penghapusan data jika batal mengajukan pinjaman",
      },
    ],
  },
];

export const invoiceFinancing = {
  title: "Invoice Financing",
  // image: require("../images/Asset Icons Website IKI Modal/Invoice_Financing.png"),
  imagePng: require("../images/JPG/Asset Icons Website IKI Modal/Invoice_Financing.png"),
  imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/Invoice_Financing.webp"),
  paragraphone: "Invoice Financing adalah pinjaman tambahan modal usaha yang ditujukan kepada UKM yang membutuhkan pembiayaan. Hanya dengan menjaminkan invoice/tagihan yang sedang berjalan (belum jatuh tempo) untuk memperoleh pinjaman hingga 2M.",
  paragraphtwo: "Anda dapat membuat arus kas perusahaan anda menjadi lancar dan lebih sehat tanpa perlu menjaminkan aset anda.",
  syaratPengajuan: ["Perseroan Terbatas (PT) atau Kemitraan Terbatas (CV)", "Usaha telah berjalan minimal 2 tahun", "Penghasilan atau total aset min. Rp 2,5 miliar/tahun", "Memiliki hubungan bisnis dengan lembaga terkemuka (perusahaan publik, perusahaan multinasional, badan usaha milik negara & lembaga pemerintah", "Memiliki kelengkapan dokumen usaha (Akta pendirian usaha, NPWP, laporan keuangan, mutasi rekening bank, profil perusahaan)"]
}

export const pinjamanProduktif = {
  title: "Pinjaman Produktif",
  // image: require("../images/Asset Icons Website IKI Modal/Pinjaman_Produktif.png"),
  imagePng: require("../images/JPG/Asset Icons Website IKI Modal/Pinjaman_Produktif.png"),
  imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/Pinjaman_Produktif.webp"),
  paragraphone: "Merupakan pinjaman modal kerja untuk perusahaan, yang diperuntukan untuk meningkatkan produktifitas dan penghasilan.",
  paragraphtwo: null,
  syaratPengajuan: ["Berdomisili di Indonesia", "Berbentuk usaha PT, CV, dan Firma", "Memiliki laporan keuangan", "Melengkapi dokumen akta pendirian NPWP, NIB, SIUP, TDP, SKDP", "Melampirkan rekening koran perusahaan"]
}

export const pinjamanKaryawan = {
  title: "Pinjaman Karyawan",
  // image: require("../images/Asset Icons Website IKI Modal/Pinjaman_Karyawan.png"),
  imagePng: require("../images/JPG/Asset Icons Website IKI Modal/Pinjaman_Karyawan.png"),
  imageWebp: require("../images/Webp/Asset Icons Website IKI Modal/Pinjaman_Karyawan.webp"),
  paragraphone: "Pinjaman karyawan merupakan produk pinjaman yang diperuntukan bagi karyawan suatu perusahaan yang telah terafiliasi dengan IKI Modal. Pinjaman ini memungkinkan karyawan untuk mendapatkan solusi finansial secara cepat, mudah dan dengan bunga yang kompetitif, dikarenakan proses verifikasi yang telah teritegrasi dengan perusahaan. Karyawan sebagai calon peminjam nantinya juga tidak perlu repot untuk melakukan pembayaran cicilan, karena cicilan dapat langsung di potong dari gaji.",
  paragraphtwo: "Untuk melihat list perusahaan yang telah terafiliasi, silahkan klik, “Ajukan Pinjaman” dan lihat pada list perusahaan. Lihat daftar perusahaan terafiliasi.",
  syaratPengajuan: ["Warga Negara Indonesia dan berdomisili di Indonesia", "Bekerja di perusahaan yang telah terafiliasi dengan IKI Modal.", "Telah melalui proses probation/masa percobaan.", "Melengkapi dokumen pendukung KTP, NPWP, ID perusahan, dan Slip gaji.", "Mendapatkan rekomendasi dari HRD perusahaan."]
}