import React from 'react'
import { Fragment, useState } from 'react';
import { virtualAccount } from '../../hardcoded/FaqData';
import AccorVirtualAccount from '../accordion/AccorVirtualAccount';

const VirtualAccount = () => {

  const [open, setOpen] = useState(false);
  const toggle = (index) => {
      if(open === index) {
          return setOpen(null)
      }
      setOpen(index)
  }

  return (
    <Fragment>
    {virtualAccount.map((item, i) => {
      return (
        <div
          className="container mx-auto flex flex-col items-center my-20 font-body"
          key={i}
        >
          {/* <h4 className="font-bold text-[41px]">{item.section}</h4>
          <hr className="border-black border-[2px] w-[50px] rounded my-5"></hr> */}

          <div className="grid grid-cols-1 px-5 -mb-10">
            {item.group.map((group, g) => {
              return (
                <>
                  <div key={g} className="mb-5 mt-0">
                    <div className="text-[25px] font-semibold text-gray-600">{group.headerTitle} </div>
                    <hr className="border border-x-2 border-gray-600"></hr>
                  </div>

                  {group.content.map((data, index) => {
                    return (
                      <AccorVirtualAccount
                        key={index}
                        open={index === open}
                        title={data.title}
                        desc={data.desc}
                        toggle={() => toggle(index)}
                      />

                    );
                  })}
                </>
              );
            })}
          </div>
        </div>
      );
    })}



  </Fragment>
  )
}

export default VirtualAccount