import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Collapse } from 'react-collapse'

const AccorInformasiUmum = ({open, toggle, title, desc}) => {
  return (
    <div className="pt-[5px mb-[10px] border border-gray-400">
        <div className="bg-white py-[15px] px-[50px] flex justify-between items-center cursor-pointer " onClick={toggle}>
            <div className="text-[18px] font-semibold text-gray-600" dangerouslySetInnerHTML={{__html:title}}></div>
            <div className="text-[30px]">
                {open ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
            </div>
        </div>
        <Collapse isOpened={open}>
            <div className="bg-white px-[50px] pb-[20px] mt-2 text-gray-600 font-normal" dangerouslySetInnerHTML={{__html:desc}}></div>
        </Collapse>
    </div>
  )
}

export default AccorInformasiUmum