import React from "react";

const AboutUsPopup = ({content, onClose}) => {
  // console.log(content)
  return (
      <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-50">
        <div className="w-full max-w-2xl relative">
          <button
            onClick={onClose}
            className="absolute top-2 right-5 text-gray-500 hover:text-gray-700 text-xl"
          >
            &times;
          </button>
          <div className="flex">
            <div className="w-3/5 bg-red-300 p-5 text-center">
              <h2 className="text-2xl font-bold text-slate-800">{content.name}</h2>
              <span className="text-base font-bold text-red-600">{content.position}</span><br /><br />
              <p className="text-sm text-justify">{content.description}</p><br />
            </div>
            <div className="w-2/5 bg-white p-5 flex items-center justify-center">
              <div className="w-[230px] h-[330px]">
                <img
                  src={content.urlJpg}
                  className="w-full h-full object-fit"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default AboutUsPopup;