import React from 'react'

const LoginBorrower = () => {
  return (
    <div className='m-5 mx-auto my-36'>
      <h2 className="text-center text-5xl my-10 text-[#878787]">Pilih Tipe Pengajuan</h2>
      <div className="flex justify-center	">
        <a href="" className="w-[30rem] hover:border rounded-xl flex m-3 p-10 hover:shadow-lg hover:shadow-black-500/50">
          <img src={require("../images/Asset Icons Website IKI Modal/ajukan-pinjaman-karyawan.png")} alt="" className="" />
          <div className="ml-20">
            <h4 className="text-2xl font-normal">Pinjaman Karyawan</h4>
            <p>Ajukan pinjaman untuk Anda karyawan perusahaan yang telah terafiliasi</p>
          </div>
        </a>
        <a href="" className="w-[30rem] hover:border rounded-xl flex m-3 p-10 hover:shadow-lg hover:shadow-black-500/50">
          <img src={require("../images/Asset Icons Website IKI Modal/ajukan-pinjaman-produktif.png")} alt="" />
          <div className="ml-20">
            <h4 className="text-2xl font-normal">Pinjaman Produktif</h4>
            <p className="">Ajukan pinjaman perusahaan dalam bentuk pinjaman invoice atau modal kerja</p>
          </div>
        </a>
      </div>
    </div>
  )
}

export default LoginBorrower