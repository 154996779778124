import Select from 'react-select';
import {useState} from 'react'
import NumberFormat from 'react-number-format';
import { capitalize } from '../util/function';


const CalculatorBulanan = ({ data }) => {
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [optionsRisk,setOptionsRisk] = useState([]);
  const [selectedRisk,setSelectedRisk]=useState();

  const [checkProduct,setCheckProduct]=useState(false);
  const [checkRisk,setCheckRisk]=useState(false);

  const [bungaPMT, setBungaPMT] = useState([])
  const [tenorPMT, setTenorPMT] = useState([])
  const [fundTarget, setFundTarget] = useState([])
  const [baseFundingPMT, setBaseFundingPMT] = useState([])

  const [resultHitungPMT, setResultHitungPMT] = useState([])

  const optionsProduct = Object.keys(data?.data || []).map((product) => ({
    value: product,
    label: capitalize(product),
  }));

  const findRisk = async () => {
    if(checkRisk && checkProduct){
      let test2 = await data?.data[selectedProduct].find(
        (e) => e.risiko === selectedRisk
      );
      setBungaPMT(test2.bunga);
    }
  }; 
  findRisk();
  const handleProductChange = value => {
    console.log(value);
    try {
      let obj = JSON.parse(JSON.stringify(value))
      setSelectedProduct(obj.value); 
      setOptionsRisk(Object.values(data?.data[value.value] || [])
        .map(resiko => ({
          value: resiko.risiko,
          label: `${capitalize(resiko.risiko)}`
        }))
      )  
      setCheckProduct(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTenorDuration = value => {
    setTenorPMT(value.target.value);
  }

  const handleRiskChange = value => {
    console.log(selectedProduct);
    try {
     let obj = JSON.parse(JSON.stringify(value));
     console.log(obj.value);
     setSelectedRisk(obj.value);
     setCheckRisk(true);
    } catch (error) {
     console.log(error);
   }
  }

  const styles = {
    option : (styles) =>{
      return{
        ...styles,
        color: "black"
      }
    }
  }

  const submitDataBulanan = async () => {
    console.log(bungaPMT, tenorPMT, fundTarget, baseFundingPMT)
    const requestOptions = {
      method: 'POST',
      headers :{'Content-Type':'application/json'},
      body: JSON.stringify({
        bunga : bungaPMT / 100,
        tenor : tenorPMT,
        tenorType : 0,
        fundTarget: fundTarget,
        baseFunding: baseFundingPMT,
        type: 1
      })
    }
    if(!bungaPMT || !tenorPMT || !fundTarget || !baseFundingPMT) {
      setResultHitungPMT("XXX.XXX")
    }else{
      const response = await fetch(process.env.REACT_APP_KALKULATOR_LENDER_TARGET_BULANAN,requestOptions);
      const data = await response.json();
      setResultHitungPMT(data.data)
    }
  }
  return (
    // <div className={`flex flex-row justify-center ${!targetInvestasiMenu ? "block" : "hidden"}`}>

    //     </div>
    <>
      <div className="flex flex-col w-[300px] md:w-[500px] px-5 md:border-r-4 border-r-white">
        <p className="text-white text-[20px] font-bold mb-3">
          Pilih Produk Pinjaman
        </p>
        <Select
          onChange={handleProductChange}
          styles={styles}
          options={optionsProduct}
        />
        <p className="text-white text-[20px] font-bold my-3">Tingkat Resiko</p>
        <Select
          isClearable={true}
          onChange={handleRiskChange}
          styles={styles}
          options={optionsRisk}
        />

        <p className="text-white text-[20px] font-bold my-3">
          Bunga (Perbulan)
        </p>
        <p className="text-white text-[40px] font-bold my-1">
          {bungaPMT + "%"}
        </p>
        <p className="text-white text-[20px] font-bold my-3">
          Target Waktu Pendanaan (Bulan)
        </p>
        <input
          type="number"
          className="text-white text-[20px] bg-transparent border-b-2 font-bold"
          placeholder="Masukkan jumlah tenor..."
          onChange={handleTenorDuration}
        ></input>
        <p className="text-white text-[20px] font-bold my-3">Pendanaan Awal</p>
        <NumberFormat
          prefix="Rp."
          displayType="number"
          thousandSeparator={true}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            setBaseFundingPMT(value);
          }}
          className="text-white bg-transparent text-[20px] font-bold my-3 border-b-2"
          placeholder="Masukkan jumlah dana awal... "
        />
        <p className="text-white text-[20px] font-bold my-3">
          Target uang yang ingin dimiliki
        </p>
        <NumberFormat
          prefix="Rp."
          displayType="number"
          thousandSeparator={true}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            setFundTarget(value);
          }}
          className="text-white bg-transparent text-[20px] font-bold my-3 border-b-2"
          placeholder="Masukkan nominal target uang... "
        />
        <div>
          <button
            className="bg-white text-[#687293] px-5 py-3 rounded-lg mb-5"
            onClick={submitDataBulanan}
          >
            Hitung
          </button>
        </div>
      </div>
      <div className="flex flex-col w-[300px] md:w-[500px] mx-5 md:items-center font-bold">
        <p>Pendanaan Bulanan</p>
        <p className="text-white text-[40px] font-bold">Rp.{resultHitungPMT}</p>
      </div>
    </>
  );
}

export default CalculatorBulanan