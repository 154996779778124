import React from 'react';
import Navbar from './component/Navbar';
import Pages from './pages/Pages';
import Footer from './component/Footer';
import WarningPopup from './component/WarningPopup';
import './styles/index.css';
import { BrowserRouter as Router} from "react-router-dom";

const App = () => {
  return (
    <Router>
    <div className='w-full'>
      <WarningPopup/>
      <Navbar/>
      <Pages />
      <Footer />
    </div>
    </Router>
  )
}

export default App