import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Collapse } from 'react-collapse'

const AccordionItems3 = ({open, toggle, title, desc, subtitle, imageUrl, imageUrl2, imageFaq}) => {
  return (
    
        <div className="pt-[5px mb-[10px] border border-gray-400">
            <div className="bg-white py-[15px] px-[50px] flex justify-between items-center cursor-pointer " onClick={toggle}>
                <div className="text-[18px] font-semibold text-gray-600">{title}</div>
                <div className="text-[30px]">
                    {open ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                </div>
            </div>
            <Collapse isOpened={open}>
                <div className="bg-white px-[50px] pb-[20px] text-[25px] font-semibold text-gray-600" dangerouslySetInnerHTML={{__html:subtitle}}></div>
                <div className="bg-white px-[50px] pb-[20px] -mt-5 text-gray-600 font-normal" dangerouslySetInnerHTML={{__html:desc}}></div>
                <img src={imageUrl} alt={imageFaq} className=" px-[0px] pb-[0px]" style={{ width: "100rem", maxWidth: "100%" }} />
                <img src={imageUrl2} alt={imageFaq} className=" px-[0px] pb-[0px]" style={{ width: "100rem", maxWidth: "100%" }} />
            </Collapse>
        </div>


  )
}

export default AccordionItems3