import React from 'react'
import "../styles/index.css"
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { useState } from 'react';

const CardMultiColumn = (props) => {
  const [active,setActive] = useState(true)

  const {text,api} = props;
  const handleSensor = () =>{
    // if (isNaN(api)) return 0
    if (typeof api == "string" && api.includes("%")) return api
    else if (typeof api == "string") return api
    else return <CountUp end={api} duration={2} onStart={() => setActive(false) } />;
  }
  return (
    <div className='m-5 flex flex-row'>
        <div className='p-6 w-[60%]'>
            <p className="font-light text-break">{text}</p>
        </div>
        <div className='w-[10%] border-l border-black'></div>
        <div className='m-2 w-[10%]'>
            <VisibilitySensor active={active}>
                {({ isVisible }) => (
                <p className="my-2 font-semibold text-[33px]">
                    {isVisible ? handleSensor() : 0}
                </p>
                )}
            </VisibilitySensor>
        </div>
    </div>
  );
}

export default CardMultiColumn