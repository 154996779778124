
const LoginLender = () => {
  return (
    <div className='m-5 mx-auto my-36'>
      <h2 className="text-center text-5xl my-10 text-[#878787]">Pilih Jenis Akun</h2>
      <div className="flex justify-center	">
        <a href="./login-lender/perorangan" className="w-[30rem] hover:border rounded-xl flex m-3 p-10 hover:shadow-lg hover:shadow-black-500/50">
          <img src={require("../images/Asset Icons Website IKI Modal/perorangan.png")} alt="" className="" />
          <div className="ml-20">
            <h4 className="text-2xl font-normal">Perorangan</h4>
            <p>Mewakili diri anda sendiri</p>
          </div>
        </a>
        <a href="./login-lender/perusahaan" className="w-[30rem] hover:border rounded-xl flex m-3 p-10 hover:shadow-lg hover:shadow-black-500/50">
          <img src={require("../images/Asset Icons Website IKI Modal/perusahaan.png")} alt="" />
          <div className="ml-20">
            <h4 className="text-2xl font-normal">Perusahaan</h4>
            <p className="">Mewakili perusahaan atau lembaga</p>
          </div>
        </a>
      </div>
    </div>
  )
}

export default LoginLender