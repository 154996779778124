import { motion } from 'framer-motion';
import { useEffect, useState } from 'react'
import SubheaderContent from '../component/SubheaderContent'
import { caraMengajukanPinjaman, keuntunganMeminjam, produkPinjaman } from '../hardcoded/subheaderHardcode'
import ImageSlider from './homepage/ImageSlider';
import ProdukPinjaman from '../component/ProdukPinjaman';
import Simulasi from './homepage/Simulasi';
import SubPilihanPinjaman from './homepage/SubPilihanPinjaman';
import { tkbData, pinjamanMarketplace } from "../services/data";
import CarouselAjukanPinjaman from './homepage/CarouselAjukanPinjaman';

const button = {
  hover: {
    scale: 1.1,
    backgroundColor: "#FF0049",
    color: "#FFFFFF",
    borderColor: "#FFFFFF",
    transition: {
      duration: 0.3,
    },
  },
};

const AjukanPinjaman = () => {
  return (
    <div>
      <div>
        <CarouselAjukanPinjaman />
        <motion.a
          href={process.env.REACT_APP_BORROWER_START_URL}
          className="bg-white py-4 px-7 rounded-[50px] text-[18px] font-bold text-[#F14651] relative -top-20 left-24"
          whileHover="hover"
          variants={button}  
        >Ajukan Pinjaman</motion.a>
      </div>
      <ProdukPinjaman />
      <SubheaderContent content={keuntunganMeminjam} />
      <SubheaderContent content={caraMengajukanPinjaman} />
    </div>
  );
}

export default AjukanPinjaman