
export const tkbData = async () => {
  // const res = await fetch ("http://103.160.62.147:2010/api/v1/tkb")
  const res = await fetch (process.env.REACT_APP_TKB_DATA);
  // console.log(res)
  const data = await res.json()
    // console.log(data.tanggal)
  return data
};

export const pinjamanMarketplace = async () => {
  const res = await fetch (process.env.REACT_APP_PINJAMAN_MARKETPLACE)
  const data = await res.json()
  return data
}

export const kalkulatorLender = async () => {
  const res = await fetch(process.env.REACT_APP_KALKULATOR_LENDER_SIMULASI);
  const data = await res.json()
  return data
}

export const laporanKeuangan = async () => {
  const res = await fetch(process.env.REACT_APP_LAPORAN_KEUANGAN);
  const data = await res.json()
  return data
}

export const laporanLabaRugiKomprehensif = async () => {
  const res = await fetch(process.env.REACT_APP_LAPORAN_LABA_RUGI_KOMPREHENSIF);
  const data = await res.json()
  return data
}