import ProductPinjamanDesc from '../component/ProductPinjamanDesc';
import { invoiceFinancing } from '../hardcoded/subheaderHardcode'
import SyaratPengajuan from '../component/SyaratPengajuan';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import CarouselInvoiceFinancing from './homepage/CarouselInvoiceFinancing';

import { motion } from 'framer-motion';

const button = {
  hover: {
    scale: 1.1,
    backgroundColor: "#FF0049",
    color: "#FFFFFF",
    borderColor: "#FFFFFF",
    transition: {
      duration: 0.3,
    },
  },
};

const InvoiceFinancing = () => {
  return (
    <div className="max-w-[100%] overflow-x-hidden">
      <div>

      <CarouselInvoiceFinancing />
      <motion.a
          href={process.env.REACT_APP_BORROWER_START_URL}
          className="bg-white py-4 px-7 rounded-[50px] text-[18px] font-bold text-[#F14651] relative -top-20 left-24"
          whileHover="hover"
          variants={button}  
        >Ajukan Pinjaman</motion.a>
      </div>
      <ProductPinjamanDesc data={invoiceFinancing} />
      <SyaratPengajuan data={invoiceFinancing} />

      <dir className="flex justify-center mb-10">
        <a
          href={process.env.REACT_APP_BORROWER_START_URL}
          className="bg-[#F14651] py-3 px-7 rounded-[50px] font-bold text-white mt-5"
        >
          Ajukan Pinjaman
        </a>
      </dir>
    </div>
  );
}

export default InvoiceFinancing