import ImageSlider from './homepage/ImageSlider';
import ProductPinjamanDesc from '../component/ProductPinjamanDesc';
import { pinjamanProduktif } from '../hardcoded/subheaderHardcode'
import SyaratPengajuan from '../component/SyaratPengajuan';
import NumberFormat from 'react-number-format';
import Select from 'react-select';

const PinjamanProduktif = () => {
  return (
    <div>
      <ImageSlider />
      <ProductPinjamanDesc data={pinjamanProduktif} />
      <SyaratPengajuan data={pinjamanProduktif} />
      <div className="my-20">
        <p className="text-center font-base text-[40px] my-3">
          Simulasi Pinjaman
        </p>
        <hr className="w-[10rem] mx-auto my-5"/>
        <p className="text-center font-thin text-xl my-5">
          Silahkan simulasikan pinjaman anda
        </p>
        <div className="container xl:w-[1200px] w-[90%] mx-auto my-4 border rounded-lg bg-[#4C6285] text-white font-body p-8">
          <div className={`flex sm:flex-row flex-col justify-center`}>
            <div className="flex flex-col sm:w-[500px] px-5 sm:border-r-2 sm:border-r-white">
                <p className="text-white text-[20px] font-bold mt-3">
                  Nilai Pinjaman
                </p>
                <NumberFormat
                  prefix="Rp."
                  displayType="number"
                  thousandSeparator={true}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    // setFunding(value);
                  }}
                  className="text-white bg-transparent text-[20px] font-thin my-3 border-b-2"
                  placeholder="Masukkan nominal pinjaman..."
                />
              
              
              <div className="my-5">
                <p className="text-white text-[20px] font-bold mb-3">
                  Tenor Pinjaman
                </p>
                <Select
                  // onChange={handleProductChange}
                  // styles={styles}
                  // options={optionsProduct}
                />
              </div>
              <button
                  className="bg-[#3c5070] border px-3 py-3 rounded-lg mb-5 w-[7rem]"
                  // onClick={submitData}
                >
                  Hitung
              </button>
            </div>
            <div className="flex flex-col sm:w-[500px] mx-5 mt-3 font-bold">
              <div className="mb-5">
                <p className="font-thin text-xl">Nilai Pencairan</p>
                <p className='text-2xl'>Rp.0</p>
              </div>
              <div>
                <p className="font-thin text-xl">Cicilan Bulanan</p>
                <p className='text-2xl'>Rp.0</p>
              </div>
            </div>
          </div>
        </div>
        <div className="relative overflow-x-auto">
          <img src={require("../images/Asset Icons Website IKI Modal/Vector 1.png")} alt="" className="overflow-hidden -z-30 absolute	bottom-[2.5rem] -right-[35rem]" />
        </div>
        <img src={require("../images/Asset Icons Website IKI Modal/Dots.png")} alt="dots" className="" />
      </div>
      <dir className="flex justify-center">
        <a href="./login" className="bg-[#F14651] py-3 px-7 rounded-[50px] font-bold text-white mt-5">Ajukan Pinjaman</a>
      </dir>
    </div>
  )
}

export default PinjamanProduktif