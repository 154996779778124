
import { useState } from "react";
import {informasiPedana} from "../../hardcoded/FaqData"
import AccordionItems3 from "../AccordionItems3";


const SectionPendana = () => {

    const [open, setOpen] = useState(false);

    
    const toggle = (index) => {
        if(open === index) {
            return setOpen(null)
        }
        setOpen(index)
    }

    
  return (
    <>

      {
        informasiPedana.map((list, index) => {
          return(
            <div
            className="container mx-auto flex flex-col items-center my-20 font-body"
            key={index}
          >
            <h4 className="font-bold text-[41px]">{list.section}</h4>
            <hr className="border-black border-[2px] w-[50px] rounded my-5"></hr>

            <div className="grid grid-cols-1 px-5">
              {list.group.map((group, g) => {
                return (
                  <>
                    <div key={g} className="mb-10 mt-10">
                      <div className="text-[28px] font-semibold text-gray-600">{group.headerTitle} </div>
                      <hr className="border border-x-2 border-gray-600"></hr>
                    </div>

                    {group.content.map((data, index) => {
                      return (
                        <AccordionItems3
                          key={index}
                          open={index === open}
                          title={data.title}
                          imageUrl={data.imageUrl}
                          imageUrl2={data.imageUrl2}
                          toggle={() => toggle(index)}
                        />
                      );
                    })}
                  </>
                );
              })}
            </div>
          </div>
          )
        })
      }


    </>
  );
}

export default SectionPendana