import { motion } from "framer-motion"

const variant = {
  button: {
    scale: 1.1,
    backgroundColor: "#FFFFFF",
    color: "#FF0049",
    borderColor: "#FF0049",
    transition: {
      duration: 0.3,
    },
  },
  card: {
    scale: 1.1,
    borderRadius: "25px",
    boxShadow:
      "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  },
};

const ProdukPinjaman = () => {
  return (
    <div className="container mx-auto flex flex-col items-center my-20 font-body">
      <h4 className="font-bold text-[41px]">Produk Pinjaman</h4>
      <hr className="border-black border-[2px] w-[50px] rounded my-5"></hr>
      <p className="text-[18px] text-primary">
        Tersedia Berbagai jenis produk pinjaman, silahkan pilih sesuai kebutuhan
        anda
      </p>
      <div className={`grid lg:grid-cols-2 sm:grid-cols-2 my-9`}>

        <motion.a
          href={process.env.REACT_APP_PINJAMAN_KARYAWAN}
          className="container shadow-xl border rounded-[25px] items-center text-center w-60 mx-10 p-3"
          key={0}
          initial={{ borderColor: "white" }}
          whileHover="card"
          variants={variant}
        >
          <img
            className="h-20 w-20 mx-auto my-6"
            src={require("../images/Asset Icons Website IKI Modal/karyawan.png")}
            alt="shield"
          ></img>
          <p className="font-bold text-[24px] my-4">Pinjaman Karyawan</p>
          <p className="font-light text-[16px] text-primary">
            Pinjaman karyawan dengan perusahaan yang telah bekerja sama dengan IKI Modal
          </p>
        </motion.a>


        <motion.a
          href="./invoice-financing"
          className="container border shadow-xl rounded-[25px] items-center text-center w-60 mx-10 p-3"
          key={0}
          initial={{ borderColor: "white" }}
          whileHover="card"
          variants={variant}
        >
          <img
            className="h-20 w-20 mx-auto my-6"
            src={require("../images/Asset Icons Website IKI Modal/document.png")}
            alt="document"
          ></img>
          <p className="font-bold text-[24px] my-4">Invoice Financing</p>
          <p className="font-light text-[16px] text-primary">
            Pinjaman uang tunai hingga 2M, hanya dengan jaminan Invoice
          </p>
        </motion.a>


      </div>
     
      <motion.a
        href={process.env.REACT_APP_BORROWER_START_URL}
        className="bg-[#F14651] border py-3 px-7 rounded-[50px] font-bold text-white"
        whileHover="button"
        variants={variant}
      >
        Ajukan Pinjaman
      </motion.a>
    </div>
  );
}

export default ProdukPinjaman