import React,{Component} from 'react';
import Slider from "react-slick";
import  "../../styles/index.css"

const images = [
  {
    id: 1,
    urlJpg : require("../../images/banner/jpg/HOMEPAGE 1.jpg"),
    urlWebp: require("../../images/banner/webp/HOMEPAGE 1.webp"),
  },
  {
    id: 2,
    urlJpg : require("../../images/banner/jpg/HOMEPAGE 2.jpg"),
    urlWebp: require("../../images/banner/webp/HOMEPAGE 2.webp"),
  },
  {
    id: 3,
    urlJpg : require("../../images/banner/jpg/Salescore_banner.jpg"),
    urlWebp: require("../../images/banner/webp/Salescore_banner.webp"),
  },
  {
    id: 4,
    urlJpg : require("../../images/banner/jpg/HOMEPAGE 4.jpg"),
    urlWebp: require("../../images/banner/webp/HOMEPAGE 4.webp"),
  },
  {
    id: 5,
    urlJpg : require("../../images/banner/jpg/HOMEPAGE 3.jpg"),
    urlWebp: require("../../images/banner/webp/HOMEPAGE 3.webp"),
  }


];

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style=
        {
          { 
            ...style, 
            display: "block", 
            position: "absolute",
            right: "3rem",
            zIndex: "50"
          }
        }
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style=
        {
          { 
            ...style, 
            display: "block", 
            position: "absolute",
            left: "3rem",
            zIndex: "50"
          }
        }
      onClick={onClick}
    />
  );
}

class ImageSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
    return (
      <div className="bg-[#E95656]">
        <Slider {...settings}>
          {images.map((image) => {
            return (
              <div>
                  {/* <img src={image.url} width="50%" alt=''></img> */}
                  <picture>
                    <source type='image/webp' srcSet={image.urlWebp}></source>
                    <source type='image/jpeg' srcSet={image.urlJpg}></source>
                    <img src={image.urlJpg} alt=""></img>
                  </picture>
              </div>
            )
          })}
        </Slider>
      </div>
    );
  }
}

export default ImageSlider
