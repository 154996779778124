import React from 'react'
import { kalkulatorLender } from '../services/data';
import { useEffect, useState } from "react";
import NumberFormat from 'react-number-format';
import { capitalize } from '../util/function';
import CalculatorBulanan from '../component/CalculatorBulanan';
import Select from 'react-select';

const Calculator = () => {
  const optionsTenorType = [
    { value: "0", label: "Bulanan" },
    { value: "1", label: "Tahunan" },
  ];

  const [data, setData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [optionsRisk,setOptionsRisk] = useState([]);
  const [selectedRisk,setSelectedRisk]=useState();

  const [riskRate, setRiskRate] = useState([]);
  const [selectedTenorType,setSelectedTenorType]=useState([]);
  const [tenorType,setTenorType]=useState([]);
  const [tenor,setTenor]=useState("");
  const [funding,setFunding]=useState([]);
  const [baseFunding,setBaseFunding]=useState([]);
  const [resultFV,setResultFV]=useState([]);

  const [bungaPMT, setBungaPMT] = useState([]);
  const [tenorPMT, setTenorPMT] = useState([]);
  const [fundTarget, setFundTarget] = useState([]);
  const [baseFundingPMT, setBaseFundingPMT] = useState([]);

  const [checkProduct,setCheckProduct]=useState(false);
  const [checkRisk,setCheckRisk]=useState(false);

  const [targetInvestasiMenu, setTargetInvestasiMenu] = useState(true);
  const [targetPendanaanBulananMenu, setTargetPendanaanBulananMenu] = useState(false);


  useEffect(() => {
    api();
  }, []);

  const api = async () => {
    const tes = await kalkulatorLender();
    setData(tes)
  };

  const optionsProduct = Object.keys(data?.data || []).map((product) => ({
    value: product,
    label: capitalize(product),
  }));

  const findRisk = async () => {
    if(checkRisk && checkProduct){
      let test2 = await data?.data[selectedProduct].find(
        (e) => e.risiko === selectedRisk
      );
      setRiskRate(test2.bunga);
      setBungaPMT(test2.bunga);
    }
  }; 

  findRisk();
  
  const styles = {
    option : (styles) =>{
      return{
        ...styles,
        color: "black"
      }
    }
  }

  const handleProductChange = value => {
    console.log(value);
    try {
      let obj = JSON.parse(JSON.stringify(value))
      setSelectedProduct(obj.value); 
      setOptionsRisk(Object.values(data?.data[value.value] || [])
        .map(resiko => ({
          value: resiko.risiko,
          label: `${capitalize(resiko.risiko)}`
        }))
      )  
      setCheckProduct(true);
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleRiskChange = value => {
    console.log(selectedProduct);
    try {
     let obj = JSON.parse(JSON.stringify(value));
     console.log(obj.value);
     setSelectedRisk(obj.value);
     setCheckRisk(true);
    } catch (error) {
     console.log(error);
   }
  }

  const handleTenorTypeChange = value => {
    console.log(value)
    try{
      let obj = JSON.parse(JSON.stringify(value));
      console.log(obj.value)
      setSelectedTenorType(obj.value);
      if(obj.value === "0"){
        setTenorType("Bulanan");
      }else{
        setTenorType("Tahunan");
      }
    }catch(error){
      console.log(error);
    }
  }

  const handleTenorDuration = value => {
    setTenor(value.target.value);
  }

  const submitData = async () => {
    console.log(riskRate,tenor,selectedTenorType,funding,baseFunding);
    const requestOptions = {
      method: 'POST',
      headers :{'Content-Type':'application/json'},
      body: JSON.stringify({
          bunga : riskRate/100,
          tenor : tenor,
          tenorType : selectedTenorType,
          monthlyFunding : funding,
          baseFunding : baseFunding,
          type : 1
      })
    }
    if(!riskRate || !tenor || (selectedTenorType.length === 0) || !funding || !baseFunding) {
      setResultFV("XXX.XXX")
    }else{
      const response = await fetch(process.env.REACT_APP_KALKULATOR_LENDER_TARGET_INVESTASI,requestOptions);
      const data = await response.json();
      setResultFV(data.data)
    }
  }

  // const submitDataBulanan = async () => {
  //   console.log(riskRate,tenor,selectedTenorType,funding,baseFunding);
  //   const requestOptions = {
  //     method: 'POST',
  //     headers :{'Content-Type':'application/json'},
  //     body: JSON.stringify({
  //       bunga : bungaPMT,
  //       tenor : tenorPMT,
  //       tenorType : 0,
  //       fundTarget: fundTarget,
  //       baseFunding: baseFundingPMT,
  //       type: 1
  //     })
  //   }
  //   if(!riskRate || tenor || selectedTenorType || funding || baseFunding) {
  //     setResultFV("XXX.XXX")
  //   }else{
  //     const response = await fetch("http://103.160.62.147:2010/api/v1/simulasi-lender/future-value",requestOptions);
  //     const data = await response.json();
  //     setResultFV(data.data)
  //   }
  // }

  return (
    <>
      <p className="text-center font-bold text-[35px] my-3">
        Simulasi Pendanaan
      </p>
      <hr className="w-12 m-auto" />
      <div className="flex justify-evenly">
        <button
          className={`text-2xl ${
            targetInvestasiMenu
              ? "text-black underline underline-offset-8"
              : "text-[#A5A5A5]"
          }`}
          onClick={() => {
            setTargetPendanaanBulananMenu(false);
            setTargetInvestasiMenu(true);
          }}
        >
          Target Investasi
        </button>
        <button
          className={`text-2xl ${
            targetPendanaanBulananMenu
              ? "text-black underline underline-offset-8"
              : "text-[#A5A5A5]"
          }`}
          onClick={() => {
            setTargetInvestasiMenu(false);
            setTargetPendanaanBulananMenu(true);
          }}
        >
          Target Pendanaan Bulanan
        </button>
      </div>
      <div className="sm:w-[40rem] md:w-[50rem] lg:w-[70rem] mx-auto my-4 border rounded-lg bg-[#546086] text-white font-body p-8">
        <div
          className={`flex flex-col sm:flex-row justify-center ${
            targetInvestasiMenu ? "block" : "hidden"
          }`}
        >
          <div className="flex flex-col w-[300px] md:w-[500px] px-5 md:border-r-4 border-r-white">
            <p className="text-white text-[20px] font-bold mb-3">
              Pilih Produk Pinjaman
            </p>
              <Select
                onChange={handleProductChange}
                styles={styles}
                options={optionsProduct}
              />
            <p className="text-white text-[20px] font-bold my-3">
              Tingkat Resiko
            </p>
              <Select
                isClearable={true}
                onChange={handleRiskChange}
                styles={styles}
                options={optionsRisk}
              />
            {/* <Select
              isClearable={true}
              onChange={handleRiskChange}
              styles={styles}
              options={optionsRisk}
            /> */}
            <p className="text-white text-[20px] font-bold my-3">
              Bunga (Perbulan)
            </p>
            <p className="text-white text-[40px] font-bold my-1">
              {riskRate + "%"}
            </p>
            <p className="text-white text-[20px] font-bold my-3">
              Tipe Pendanaan
            </p>
              <Select
                onChange={handleTenorTypeChange}
                options={optionsTenorType}
                styles={styles}
              />
            <p className="text-white text-[20px] font-bold my-3">
              Target Waktu Pendanaan {tenorType}
            </p>
            <input
              type="number"
              className="text-white text-[20px] bg-transparent border-b-2 font-bold"
              placeholder="Masukkan jumlah tenor..."
              onChange={handleTenorDuration}
            ></input>
            <p className="text-white text-[20px] font-bold my-3">
              Pendanaan {tenorType}
            </p>
            <NumberFormat
              prefix="Rp."
              displayType="number"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setFunding(value);
              }}
              className="text-white bg-transparent text-[20px] font-bold my-3 border-b-2"
              placeholder="Masukkan jumlah dana..."
            />
            <p className="text-white text-[20px] font-bold my-3">
              Pendanaan Awal
            </p>
            <NumberFormat
              prefix="Rp."
              displayType="number"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setBaseFunding(value);
              }}
              className="text-white bg-transparent text-[20px] font-bold my-3 border-b-2"
              placeholder="Masukkan jumlah dana awal... "
            />

            <div>
              <button
                className="bg-white text-[#687293] px-5 py-3 rounded-lg mb-5"
                onClick={submitData}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="flex flex-col w-[300px] md:w-[500px] mx-5 md:items-center font-bold">
            <p>Target uang yang ingin dimiliki</p>
            <p className="text-white text-[40px] font-bold">Rp.{resultFV}</p>
          </div>
        </div>
        <div
          className={`flex flex-col md:flex-row justify-center ${
            !targetInvestasiMenu ? "block" : "hidden"
          }`}
        >
          <CalculatorBulanan data={data} />
          {/* <div className="flex flex-col w-[500px] px-5 border-r-4 border-r-white">
            <p className="text-white text-[20px] font-bold mb-3">
              Pilih Produk Pinjaman
            </p>
            <Select
              onChange={handleProductChange}
              styles={styles}
              options={optionsProduct}
            />
            <p className="text-white text-[20px] font-bold my-3">
              Tingkat Resiko
            </p>
            <Select
              isClearable={true}
              onChange={handleRiskChange}
              styles={styles}
              options={optionsRisk}
            />
            <p className="text-white text-[20px] font-bold my-3">
              Bunga (Perbulan)
            </p>
            <p className="text-white text-[40px] font-bold my-1">
              {riskRate + "%"}
            </p>
            <p className="text-white text-[20px] font-bold my-3">
              Target Waktu Pendanaan (Bulan)
            </p>
            <input
              type="number"
              className="text-white text-[20px] bg-transparent border-b-2 font-bold"
              placeholder="Masukkan jumlah tenor..."
              onChange={handleTenorDuration}
            ></input>
            <p className="text-white text-[20px] font-bold my-3">
              Pendanaan Awal
            </p>
            <NumberFormat
              prefix="Rp."
              displayType="number"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setBaseFunding(value);
              }}
              className="text-white bg-transparent text-[20px] font-bold my-3 border-b-2"
              placeholder="Masukkan jumlah dana awal... "
            />
            <p className="text-white text-[20px] font-bold my-3">
              Target uang yang ingin dimiliki
            </p>
            <NumberFormat
              prefix="Rp."
              displayType="number"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setBaseFunding(value);
              }}
              className="text-white bg-transparent text-[20px] font-bold my-3 border-b-2"
              placeholder="Masukkan nominal target uang... "
            />
            <div>
              <button
                className="bg-white text-[#687293] px-5 py-3 rounded-lg mb-5"
                onClick={submitData}
              >
                Hitung
              </button>
            </div>
          </div>
          <div className="flex flex-col w-[500px] mx-5 items-center font-bold">
            <p>Pendanaan Bulanan</p>
            <p className='text-white text-[40px] font-bold'>Rp.{resultFV}</p>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Calculator