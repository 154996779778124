import { motion } from "framer-motion";

const variant = {
  hover: {
    scale: 1.1,
    borderRadius: "25px",
    boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  },
};

const Login = () => {
  return (
    <div className="m-5 mx-auto mt-10 mb-36">
      <h2 className="text-center md:text-5xl text-4xl my-10 text-[#878787]">
        Login Sebagai
      </h2>
      <div className="flex lg:justify-center lg:flex-row flex-col mx-auto lg:w-[80rem] sm:w-[35rem]">
        {/* <a href={process.env.REACT_APP_BORROWER_START_URL} className="sm:w-[30rem] w-[15rem] hover:border rounded-xl flex sm:flex-row flex-col m-3 sm:p-10 p-5 hover:shadow-lg hover:shadow-black-500/50 lg:mx-2 mx-auto">
          <img src={require("../images/Asset Icons Website IKI Modal/factory_worker_flatline.png")} alt="" className="sm:w-[10rem] w-[5rem] sm:mx-0 mx-auto" />
          <div className="sm:ml-20 mt-5 sm:mt-0">
            <h4 className="text-2xl font-normal">Borrower</h4>
            <p>Bagi anda yang membutuhkan solusi finansial yang cepat dan mudah</p>
          </div>
        </a> */}
        <motion.a
          href={process.env.REACT_APP_BORROWER_START_URL}
          className="sm:w-[30rem] w-[15rem] border flex sm:flex-row flex-col m-3 sm:p-10 p-5 lg:mx-10 mx-auto"
          whileHover="hover"
          initial={{ borderColor: "white" }}
          variants={variant}
        >
          <img
            src={require("../images/Asset Icons Website IKI Modal/factory_worker_flatline.png")}
            alt=""
            className="sm:w-[10rem] w-[5rem] sm:mx-0 mx-auto"
          />
          <div className="sm:ml-20 mt-5 sm:mt-0">
            <h4 className="text-2xl font-normal">Borrower</h4>
            <p>
              Bagi anda yang membutuhkan solusi finansial yang cepat dan mudah
            </p>
          </div>
        </motion.a>
        {/* <a
          href={process.env.REACT_APP_LENDER_START_URL}
          className="sm:w-[30rem] w-[15rem] hover:border rounded-xl flex sm:flex-row flex-col m-3 sm:p-10 p-5 hover:shadow-lg hover:shadow-black-500/50 lg:mx-2 mx-auto"
        >
          <img
            src={require("../images/Asset Icons Website IKI Modal/entrepreneurs_flatline.png")}
            alt=""
            className="sm:w-[10rem] w-[5rem] sm:mx-0 mx-auto"
          />
          <div className="sm:ml-20 mt-5 sm:mt-0">
            <h4 className="text-2xl font-normal">Lender</h4>
            <p className="">
              Bagi anda yang ingin memberikan pinjaman dan bertumbuh bersama
              kami
            </p>
          </div>
        </a> */}
        <motion.a
          href={process.env.REACT_APP_LENDER_START_URL}
          className="sm:w-[30rem] w-[15rem] border flex sm:flex-row flex-col m-3 sm:p-10 p-5 lg:mx-2 mx-auto"
          whileHover="hover"
          initial={{ borderColor: "white" }}
          variants={variant}
        >
          <img
            src={require("../images/Asset Icons Website IKI Modal/entrepreneurs_flatline.png")}
            alt=""
            className="sm:w-[10rem] w-[5rem] sm:mx-0 mx-auto"
          />
          <div className="sm:ml-20 mt-5 sm:mt-0">
            <h4 className="text-2xl font-normal">Lender</h4>
            <p className="">
              Bagi anda yang ingin memberikan pinjaman dan bertumbuh bersama
              kami
            </p>
          </div>
        </motion.a>
      </div>
    </div>
  );
}

export default Login