import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './HomePage'
import Calculator from './Calculator'
import Login from './Login'
import LoginLender from './LoginLender'
import LoginLenderPerusahaan from './LoginLenderPerusahaan'
import LoginLenderPerorangan from './LoginLenderPerorangan'
import LoginBorrower from './LoginBorrower'
import BeriPinjaman from './BeriPinjaman'
import AjukanPinjaman from './AjukanPinjaman'
import InvoiceFinancing from './InvoiceFinancing'
import PinjamanKaryawan from './PinjamanKaryawan'
import PinjamanProduktif from './PinjamanProduktif'
import AboutUs from './AboutUs'
import LegalFaq from './LegalFaq'
// import CustomArrows from './homepage/CustomArrows'

const Pages = () => {
  return (
      <div>
      <Routes>
        <Route 
          path='/'
          element={<HomePage />}
        />
        <Route path='/kalkulator' element={<Calculator />} />
        <Route path='/login' element={<Login />} />
        <Route path='/login-lender' element={<LoginLender />} />
        <Route path='/login-lender/perusahaan' element={<LoginLenderPerusahaan />} />
        <Route path='/login-lender/perorangan' element={<LoginLenderPerorangan />} />
        <Route path='/login-borrower' element={<LoginBorrower />} />
        <Route path='/beri-pinjaman' element={<BeriPinjaman />} />
        <Route path='/ajukan-pinjaman' element={<AjukanPinjaman />} />
        <Route path='/invoice-financing' element={<InvoiceFinancing />} />
        <Route path='/pinjaman-karyawan' element={<PinjamanKaryawan />} />
        <Route path='/pinjaman-produktif' element={<PinjamanProduktif />} />
        <Route path='/tentang-kami' element={<AboutUs />} />
        <Route path='/legal-faq' element={<LegalFaq />} />
        {/* <Route path='/awikwok' element={<CustomArrows />} /> */}
      </Routes>
      </div>
  )
}

export default Pages