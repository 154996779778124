import ImageSlider from './homepage/ImageSlider';
import ProductPinjamanDesc from '../component/ProductPinjamanDesc';
import { pinjamanKaryawan } from '../hardcoded/subheaderHardcode'
import SyaratPengajuan from '../component/SyaratPengajuan';
import NumberFormat from 'react-number-format';
import Select from 'react-select';

const PinjamanKaryawan = () => {
  return (
    <div>
      <ProductPinjamanDesc data={pinjamanKaryawan} />
      <SyaratPengajuan data={pinjamanKaryawan} />

      <dir className="flex justify-center mb-10">
        <a
          href={process.env.REACT_APP_BORROWER_START_URL}
          className="bg-[#F14651] py-3 px-7 rounded-[50px] font-bold text-white mt-5"
        >
          Ajukan Pinjaman
        </a>
      </dir>
    </div>
  );
}

export default PinjamanKaryawan