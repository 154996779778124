import React from 'react'
import "../../styles/index.css"
import { keutunganPendanaanPeminjam } from '../../hardcoded/subheaderHardcode'
import SubheaderContent from '../../component/SubheaderContent'

const Subheader = () => {
  return(
    <>
    <SubheaderContent content={keutunganPendanaanPeminjam} />
    </>
  )
}
        
export default Subheader