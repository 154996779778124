
const LoginLenderPerorangan = () => {
  return (
    <div className='m-5 mx-auto my-36'>
      <h2 className="text-center text-5xl my-10 text-[#878787]">Pilih Tipe Pendanaan</h2>
      <div className="flex justify-center	">
        <a href="" className="w-[30rem] hover:border rounded-xl flex m-3 p-10 hover:shadow-lg hover:shadow-black-500/50">
          <img src={require("../images/Asset Icons Website IKI Modal/danai-pinjaman-karyawan.png")} alt="" className="" />
          <div className="ml-20">
            <h4 className="text-2xl font-normal">Danai Pinjaman Karyawan</h4>
            <p>Mendanai pendana untuk pinjaman karyawan</p>
          </div>
        </a>
        <a href="" className="w-[30rem] hover:border rounded-xl flex m-3 p-10 hover:shadow-lg hover:shadow-black-500/50">
          <img src={require("../images/Asset Icons Website IKI Modal/danai-pinjaman-produktif.png")} alt="" />
          <div className="ml-20">
            <h4 className="text-2xl font-normal">Danai Pinjaman Produktif</h4>
            <p className="">Mendanai pendana untuk pinjaman produktif (invoice dan modal kerja)</p>
          </div>
        </a>
      </div>
    </div>
  )
}

export default LoginLenderPerorangan